import React from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { selectIntakeDiscounts } from 'app/selectors/customer';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import './css/DiscountCouponsList.scss';

const CouponItem = ({ key, discount, priceCalculator }) => {
  const isLab = discount?.get('product_name')?.slice(-4) === '_lab';
  const appliedFor = isLab ? 'labs' : 'subscription';

  return (
    <div key={key} className="price_row mt24">
      <div>
        {`“${discount.get('coupon').get('code')}” applied for ${appliedFor}.`}
        <br />
        {discount.get('coupon').get('name')}
      </div>
      <PriceFormatter price={priceCalculator.discountPrice(discount)} showCents={true} negative={true} />
    </div>
  );
};

const DiscountCouponsList = ({ priceCalculator }) => {
  const discounts = useAppSelector(selectIntakeDiscounts);

  return (
    <div className="discount-coupons-list">
      {discounts?.map((discount) => (
        <CouponItem key={discount.get('id')} discount={discount} priceCalculator={priceCalculator} />
      ))}
    </div>
  );
};

export default DiscountCouponsList;
