import { PharmacyShipment } from 'app/types/admin/customerUser';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { flow, isString, map, max } from 'lodash/fp';
import * as Yup from 'yup';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('US/Pacific');

export const getMaxShipmentDate: (arr: Array<PharmacyShipment>) => string | undefined = flow(map('date_shipped'), max);

export const isAutoChurnDate = (_date: string) => false;
// dayjs().diff(date, 'm') > (window as any).DELAY_BEFORE_AUTO_CHURN_MINUTES

export const isCreateIntakeSoon = (date: string, delayInMinutes: number) =>
  dayjs().subtract(3, 'd').diff(date, 'm') > delayInMinutes;

export function formatDate(date, format, _locale?) {
  if (isString(date) && !date.includes(':')) {
    // This is fix for date only fields
    return date && dayjs(date).format(format);
  }

  return date && dayjs(date).tz().format(format);
}

export const validateForFormik = (schema: Yup.ObjectSchema<any>, values: Record<string, any>) =>
  Object.keys(values).reduce((acc: Record<string, string>, path) => {
    try {
      schema.validateSyncAt(path, values);

      return acc;
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        return {
          ...acc,
          [e.path as string]: e.message,
        };
      }
    }

    return acc;
  }, {});
