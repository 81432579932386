/* eslint-disable no-case-declarations */
import { MagicianSubProducts } from 'app/constants/Products';
import BasePriceCalculator from 'app/helpers/productSpecificPriceCalculators/basePriceCalculator';

// Key is a number of months
const MAGICIAN_SUBSCRIPTION_COST_NO_BB = {
  [MagicianSubProducts.Semaglutide]: {
    1: 299_99,
    3: 900_99,
    6: 1800_99,
    12: 3600_99,
  },
  [MagicianSubProducts.Tirzepatide]: {
    1: 499_99,
    3: 1500_99,
    6: 3000_99,
    12: 6000_99,
  },
};

// Key is a number of months
const MAGICIAN_SUBSCRIPTION_COST_BB_ON_ANOTHER_PROTOCOL = {
  [MagicianSubProducts.Semaglutide]: {
    1: 289_99,
    3: 279_99,
    6: 269_99,
    12: 259_99,
  },
  [MagicianSubProducts.Tirzepatide]: {
    1: 499_99,
    3: 489_99,
    6: 479_99,
    12: 469_99,
  },
};

const MAGICIAN_SUBSCRIPTION_COST_BB_INCLUDED = {
  [MagicianSubProducts.Semaglutide]: {
    1: 299_99,
    3: 900_99,
    6: 1800_99,
    12: 3600_99,
  },
  [MagicianSubProducts.Tirzepatide]: {
    1: 499_99,
    3: 1500_99,
    6: 3000_99,
    12: 6000_99,
  },
};

export const MAGICIAN_MONTHLY_PRICE_NO_BB = {
  [MagicianSubProducts.Semaglutide]: {
    1: 299_99,
    3: 289_99,
    6: 279_99,
    12: 269_99,
  },
  [MagicianSubProducts.Tirzepatide]: {
    1: 499_99,
    3: 489_99,
    6: 479_99,
    12: 469_99,
  },
};

export const MAGICIAN_MONTHLY_PRICE_BB_INCLUDED = {
  [MagicianSubProducts.Semaglutide]: {
    1: 349_98,
    3: 339_98,
    6: 329_98,
    12: 319_98,
  },
  [MagicianSubProducts.Tirzepatide]: {
    1: 549_98,
    3: 539_98,
    6: 529_98,
    12: 519_98,
  },
};

class MagicianPriceCalculator extends BasePriceCalculator {
  drugVariant: MagicianSubProducts;
  crlFeatureEnabledEncloPregWeightLoss: boolean;

  MAGICIAN_LAB_COST = 69_99;
  LEGACY_MAGICIAN_LAB_COST = 65_00;

  constructor(
    includeBB: boolean,
    includeBBonAnotherProtocol: boolean,
    intakeName: string,
    useOwnLab: boolean,
    multiMonthPlan: 1 | 3 | 6 | 12,
    drugVariant: MagicianSubProducts,
    crlFeatureEnabledEncloPregWeightLoss = false,
    subscriptionPaid = false,
    labPaid = false,
  ) {
    super(includeBB, includeBBonAnotherProtocol, intakeName, useOwnLab, multiMonthPlan, subscriptionPaid, labPaid);

    this.drugVariant = drugVariant;
    this.crlFeatureEnabledEncloPregWeightLoss = crlFeatureEnabledEncloPregWeightLoss;
  }

  monthlyCost() {
    if (this.includeBB || this.includeBBonAnotherProtocol) {
      return MAGICIAN_MONTHLY_PRICE_BB_INCLUDED[this.drugVariant][this.multiMonthPlan];
    }
    return MAGICIAN_MONTHLY_PRICE_NO_BB[this.drugVariant][this.multiMonthPlan];
  }

  subscriptionCost() {
    if (this.subscriptionPaid) return 0;

    if (this.includeBB) {
      return MAGICIAN_SUBSCRIPTION_COST_BB_INCLUDED[this.drugVariant][this.multiMonthPlan];
    } else if (this.includeBBonAnotherProtocol) {
      return MAGICIAN_SUBSCRIPTION_COST_BB_ON_ANOTHER_PROTOCOL[this.drugVariant][this.multiMonthPlan];
    }

    return MAGICIAN_SUBSCRIPTION_COST_NO_BB[this.drugVariant][this.multiMonthPlan];
  }

  atHomeLabPrice() {
    return this.crlFeatureEnabledEncloPregWeightLoss ? this.MAGICIAN_LAB_COST : this.LEGACY_MAGICIAN_LAB_COST;
  }

  labCost() {
    if (
      !this.labPaid &&
      (this.intakeName.includes('onboarding') || this.intakeName.includes('follow_up')) &&
      !this.useOwnLab
    ) {
      return this.atHomeLabPrice();
    }

    return 0;
  }

  totalDueTodayForCurrentProduct() {
    if (this.intakeName.includes('onboarding')) {
      return this.subscriptionCost() + this.labCost();
    }

    return this.labCost();
  }

  // Discounts
  bbDiscount() {
    if (!(this.includeBB || this.includeBBonAnotherProtocol) || !this.multiMonthPlan || this.multiMonthPlan < 3) {
      return 0;
    }

    return this.bbPotentialDiscount();
  }

  bbPotentialDiscount() {
    const map = {
      1: 0,
      3: 10_00,
      6: 20_00,
      12: 30_00,
    };

    return map[this.multiMonthPlan || 1];
  }

  nonDiscountedPrice() {
    if (!this.bbDiscount()) {
      return 0;
    }

    return this.monthlyCost() + this.bbDiscount();
  }
}

export default MagicianPriceCalculator;
