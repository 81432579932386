/* eslint-disable no-case-declarations */
import BasePriceCalculator from 'app/helpers/productSpecificPriceCalculators/basePriceCalculator';

const LOVER_STRENGTH_PRICES = {
  '2_5_mg': 99_99,
  '5_mg': 149_99,
};

const LOVER_NUMBER_OF_DOSES_PRICES = {
  4: 59_99,
  8: 109_99,
  12: 159_99,
};

class LoverPriceCalculator extends BasePriceCalculator {
  dailyStrength: string | null;
  numberOfDoses: 4 | 8 | 12 | null;
  otherActiveSubscription: boolean;

  SHIPPING_PRICE = 9_99;

  constructor(
    includeBB: boolean,
    includeBBonAnotherProtocol: boolean,
    intakeName: string,
    dailyStrength: string | null = null,
    numberOfDoses: null | 4 | 8 | 12 = null,
    otherActiveSubscription: boolean,
  ) {
    super(includeBB, includeBBonAnotherProtocol, intakeName);

    this.dailyStrength = dailyStrength;
    this.numberOfDoses = numberOfDoses;
    this.otherActiveSubscription = otherActiveSubscription;
  }

  monthlyCost() {
    let subsCost = 0;
    if (this.dailyStrength) {
      subsCost = LOVER_STRENGTH_PRICES[this.dailyStrength];
    }
    if (this.numberOfDoses) {
      subsCost = LOVER_NUMBER_OF_DOSES_PRICES[this.numberOfDoses];
    }

    return this.includeBB ? subsCost + this.BB_PRICE : subsCost;
  }

  subscriptionCost() {
    if (this.subscriptionPaid) return 0;

    return this.monthlyCost();
  }

  totalDueTodayForCurrentProduct() {
    if (!this.intakeName.includes('onboarding')) {
      return 0;
    }

    return this.subscriptionCost() + this.shippingPrice();
  }

  freeShipping() {
    return !(!this.otherActiveSubscription && this.subscriptionCost() <= 10000);
  }

  shippingPrice() {
    return this.freeShipping() ? 0 : this.SHIPPING_PRICE;
  }
}

export default LoverPriceCalculator;
