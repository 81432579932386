import React from 'react';
import DrugsBottle from 'images/sub-products/magician/magician-drugs.png';
import { useFormikContext } from 'formik';
import { MAGICIAN_MONTHLY_PRICE_BB_INCLUDED } from 'app/helpers/productSpecificPriceCalculators/magicianPriceCalculator';
import { AvailableProducts, MagicianSubProducts } from 'app/constants/Products';
import GenericMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericMultimonthPlanSelectionForm';

const MagicianMultimonthPlanSelectionForm = ({ handleChangeProduct }) => {
  const { values } = useFormikContext<{
    multimonth_plan: number;
    drug_variant: string;
  }>();
  const selectedSubProduct = values.drug_variant || MagicianSubProducts.Semaglutide;

  const multimonthConfig = [
    {
      value: 12,
      specialLabel: 'Best Value',
      savings: '$XXX yearly savings',
      subheader: 'Bills every 12 months',
      title: 'Yearly Plan',
    },
    {
      value: 6,
      specialLabel: 'Most Popular',
      savings: '$XXX yearly savings',
      subheader: 'Bills every 6 months',
      title: 'Semiannual Plan',
    },
    {
      value: 3,
      savings: '$XXX yearly savings',
      subheader: 'Bills every 3 months',
      title: 'Quarterly Plan',
    },
    {
      value: 1,
      subheader: 'Bills every month',
      title: 'Monthly Plan',
    },
  ];

  return (
    <GenericMultimonthPlanSelectionForm
      productName={AvailableProducts.Magician}
      selectedSubProduct={selectedSubProduct}
      multimonthConfig={multimonthConfig}
      handleChangeProduct={handleChangeProduct}
      showBreadcrumbs={true}
      drugsImage={DrugsBottle}
      priceConfig={MAGICIAN_MONTHLY_PRICE_BB_INCLUDED}
    />
  );
};

export default MagicianMultimonthPlanSelectionForm;
