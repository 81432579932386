import React from 'react';
import '../css/ProductImage.scss';
import BottleEncloPregnolone from 'images/sub-products/king/bottle_enclo_pregnolone.png';
import BottleTrt from 'images/sub-products/king/bottle_trt.png';
import BottleEncloPregnoloneTrt from 'images/sub-products/king/bottle_enclo_pregnolone_trt.png';
import BottleSemaglutide from 'images/sub-products/magician/bottle_semaglutide.png';
import BottleTirzepatide from 'images/sub-products/magician/bottle_tirzepatide.png';
import { AvailableProducts, KingV2SubProducts, MagicianSubProducts } from 'app/constants/Products';

const ProductImage = ({ productName, subProductName, className = '' }) => {
  const ImagesMapping = {
    [AvailableProducts.King]: {
      [KingV2SubProducts.EncloPregnolone]: BottleEncloPregnolone,
      [KingV2SubProducts.Trt]: BottleTrt,
      [KingV2SubProducts.EncloPregnoloneTrt]: BottleEncloPregnoloneTrt,
    },
    [AvailableProducts.Magician]: {
      [MagicianSubProducts.Semaglutide]: BottleSemaglutide,
      [MagicianSubProducts.Tirzepatide]: BottleTirzepatide,
    },
  };

  return (
    <div className={`product-image ${className}`}>
      <img src={ImagesMapping[productName][subProductName]} />
    </div>
  );
};

export default ProductImage;
