import React, { useContext } from 'react';
import { Formik } from 'formik';
import ProgressBar from 'app/components/customer/questions/ProgressBar';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import { apiRequestUserCommand } from 'app/actions/customer';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntake } from 'app/selectors/customer';
import Checkbox from 'app/components/common/Checkbox';
import { ProductContext } from 'app/components/customer/Product';
import * as selectors from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import ImageBenefits1Src from 'images/product-selection-img-1.png';
import ImageBenefits2Src from 'images/product-selection-img-2.png';
import 'app/components/customer/steps/Payment/css/ProductSelection.scss';
import protocolNames from 'app/utils/protocolNames';

const ProductSelection = () => {
  const productCtx = useContext(ProductContext);
  const dispatch = useAppDispatch();
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, productCtx?.selectedProduct));
  const intake = useAppSelector(selectCurrentIntake);
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectFurthestProductStepPath({ customer: stateAfterSuccess }, 'king'));
  const defaultValue = intake.get('questionnaire').get('product_selection_step_completed')
    ? product.get('opt_in_choice').get('enclomiphene_with_pregnenolone')
    : true;
  const initialValues = { enclomiphene_with_pregnenolone: defaultValue };
  const onSubmit = ({ enclomiphene_with_pregnenolone }) => {
    const cmdType = 'change_product_version';
    const action = apiRequestUserCommand({
      cmdType,
      params: {
        product_name: 'king',
        enclomiphene_with_pregnenolone,
      },
      context: {
        onSuccessActionCreator: () => {
          return apiRequestUserCommand({
            cmdType: 'answer_questions',
            params: {
              intake: intake.get('name'),
              product_selection_step_completed: true,
            },
            context: { onSuccessActionCreator },
          });
        },
      },
    });
    dispatch(action);
  };
  return (
    <div className="product-selection">
      <ProgressBar title={`${protocolNames['king']} Protocol Improvements`} className="hide-progress" />

      <div className="product-selection__wrap">
        <h2>
          Maximus is excited to announce improvements to<span>{protocolNames['king']} Protocol</span>You are eligible
          for these improvements today.
        </h2>

        <div className="product-selection__benefits benefits">
          <p className="benefits__title">What’s new?</p>

          <div className="benefits__item">
            <img src={ImageBenefits1Src} alt="Enclomiphene + Pregnenolone" />
            <p className="benefits__info">More than just enclomiphene</p>
            <p className="benefits__text">
              <b>Pregnenolone in addition to enclomiphene.</b> Each dose of {protocolNames['king']} Protocol now
              contains pregnenolone. Pregnenolone, nicknamed the ‘grandfather hormone’, is a foundational precursor to
              critical hormones like testosterone. <sup>1,2</sup> Pregnenolone has been demonstrated to boost energy,
              improve mood, and promote calmness. <sup>3,4,5,6,7</sup> Depending on your dosage of enclomiphene, you
              will also receive 5mg, 10mg, or 20mg of pregnenolone with your dose of {protocolNames['king']} Protocol.
              All dosage titration is based on doctor discretion.
            </p>
          </div>

          <div className="benefits__item">
            <img src={ImageBenefits2Src} alt={`Bottle with ${protocolNames['king']} Protocol`} />
            <p className="benefits__info">Enhanced delivery</p>
            <p className="benefits__text">
              <b>Orally disintegrating tablets (ODT).</b> All customers will receive ODTs instead of capsules with this
              protocol enhancement. ODTs are dissolved sublingually (under the tongue), which allows the pregnenolone to
              deliver its benefits more effectively. <sup>3</sup> For those that don’t care for taking medication
              sublingually, you can swallow the tablet as if it was a capsule or pill.
            </p>
          </div>

          <div className="benefits__item benefits__item_pure">
            <p className="benefits__text">
              You will keep your attending Maximus doctor and the pattern of care you are accustomed to with Maximus.
              The only change here is the addition of pregnenolone to enclomiphene, which is taken as an ODT – all at{' '}
              <b>no additional cost to you.</b>
            </p>
          </div>
        </div>

        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, values: { enclomiphene_with_pregnenolone }, setFieldValue }) => (
            <div className="product-selection__form">
              <Checkbox
                label={
                  <p className="bold">
                    Start sending me the improved {protocolNames['king']} Protocol beginning with my next shipment.
                  </p>
                }
                testId="enclomiphene-with-pregnenolone"
                name="enclomiphene_with_pregnenolone"
                checked={enclomiphene_with_pregnenolone}
                onChange={(evt) => setFieldValue('enclomiphene_with_pregnenolone', evt.target.checked)}
              />
              <BackNextFooter
                onNext={handleSubmit}
                nextText="Submit"
                noBack={true}
                className="mt40"
                horizontalPadding={false}
              />
            </div>
          )}
        </Formik>

        <p className="product-selection__refs">
          <p>References:</p>
          <ul>
            <li>
              Havlíková H, Hill M, Hampl R, Stárka L. Sex- and Age-Related Changes in Epitestosterone in Relation to
              Pregnenolone Sulfate and Testosterone in Normal Subjects. J Clin Endocrinol Metab. 2002;87(5):2225-2231.
            </li>
            <li>
              Roberts E. Pregnenolone—from selye to Alzheimer and a model of the pregnenolone sulfate binding site on
              the GABAA receptor. Biochem Pharmacol. 1995;49(1):1-16.
            </li>
            <li>Exclusive Interview with Ray Sahelian, M.D. The Promise of Pregnenolon</li>
            <li>
              Roberts E. Pregnenolone—from selye to Alzheimer and a model of the pregnenolone sulfate binding site on
              the GABAA receptor. Biochem Pharmacol. 1995;49(1):1-16.
            </li>
            <li>Wang M. Neurosteroids and GABA-A Receptor Function. Front Endocrinol (Lausanne). 2011;2:44.</li>
            <li>
              Maurice T. Neurosteroids and sigma1 receptors, biochemical and behavioral relevance. Pharmacopsychiatry.
              2004;37 Suppl 3:S171-S182.
            </li>
            <li>
              Kourrich S, Su TP, Fujimoto M, Bonci A. The sigma-1 receptor: roles in neuronal plasticity and disease.
              Trends Neurosci. 2012;35(12):762-771.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default ProductSelection;
