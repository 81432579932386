import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';

export function useExperiment(key: string, defaultValue: string): string {
  const customer = useAppSelector(selectCustomer);
  const optimizelyExperiments = customer.get('experiments');
  const currentExperiment = optimizelyExperiments.find((e) => e.get('variation_key') === key);
  const variationValue = currentExperiment && currentExperiment.get('variation_value');

  return variationValue ?? defaultValue;
}
