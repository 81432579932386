export enum AvailableProducts {
  Warrior = 'warrior',
  Lover = 'lover',
  King = 'king',
  Magician = 'magician',
}

export enum KingV2SubProducts {
  Enclo = 'enclomiphene',
  EncloPregnolone = 'enclomiphene_pregnolone',
  Trt = 'oral_trt',
  EncloPregnoloneTrt = 'enclomiphene_pregnolone_oral_trt',
}

export enum MagicianSubProducts {
  Semaglutide = 'semaglutide',
  Tirzepatide = 'tirzepatide',
}

export enum KingV2LabKitTypes {
  AtHomeLabKit = 'at_home_lab_kit',
  WalkIn = 'lab_walk_in',
  UseYourOwnLab = 'use_own_lab',
}

export const MultiMonthPlanMapping = {
  1: 'Monthly',
  3: 'Quarterly',
  6: 'Semiannual',
  12: 'Yearly',
};

export const UserSelectedSubProductMapping = {
  [KingV2SubProducts.EncloPregnolone]: 'enclo+preg',
  [KingV2SubProducts.Trt]: 'oral_trt',
  [KingV2SubProducts.EncloPregnoloneTrt]: 'oral_trt+enclo+preg',
};

export const InitialStripeFields = {
  number: {},
  cvc: {},
  expiry: {},
};

export const loverPriceMapping = {
  '2_5_mg': 99.99,
  '5_mg': 149.99,
  'legacy': 149.99,
  4: 59.99,
  8: 109.99,
  12: 159.99,
};
