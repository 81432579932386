import React from 'react';

import '../css/ProductSelection.scss';
import { useFormikContext } from 'formik';
import { AvailableProducts, MagicianSubProducts } from 'app/constants/Products';
import GenericProductSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericProductSelectionForm';

type Props = {
  firstTimeChoice?: boolean;
  changeProductHandler?: ((kingProductType: string, setFieldValue: any) => void) | null;
};

const MagicianProductSelectionForm = ({ firstTimeChoice = true, changeProductHandler = null }: Props) => {
  const { values, setFieldValue } = useFormikContext<{
    drug_variant: string;
  }>();

  const handleChange = (value: string) => {
    setFieldValue('drug_variant', value);

    if (changeProductHandler) {
      changeProductHandler(value, setFieldValue);
    }
  };

  const subProductsConfig = [
    {
      name: MagicianSubProducts.Semaglutide,
      price: 26999,
      description:
        'Unlock a slimmer you with Semaglutide - proven to help shed an average of 16.9% body weight in 68 weeks.',
    },
    {
      name: MagicianSubProducts.Tirzepatide,
      price: 46999,
      description:
        'Discover the most effective weight loss tool on the market. People using Tirzepatide lose more weight than any other GLP-1 product. Tirzepatide patients lose on average 22.5% of body weight in 72 weeks.',
    },
  ];

  return (
    <div className="choose_product">
      <GenericProductSelectionForm
        productName={AvailableProducts.Magician}
        selectedSubProduct={values.drug_variant as MagicianSubProducts}
        firstTimeChoice={firstTimeChoice}
        subProductsConfig={subProductsConfig}
        handleChange={handleChange}
      />
    </div>
  );
};

export default MagicianProductSelectionForm;
