import React, { FC } from 'react';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { Formik, FormikProps } from 'formik';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useAppSelector } from 'app/helpers/hooks';
import { ImmutableMap } from 'app/types/admin';
import { RootState } from 'app/configureStore';
import { CustomerShippingAddress } from 'app/types';
import Input, { InputMask } from 'app/components/common/formik/Input';
import Spinner from 'app/components/customer/Spinner';
import { Dropdown as BlackDropdown } from 'app/components/common/formik/Dropdown';
import { push } from 'connected-react-router/immutable';
import * as selectors from 'app/selectors/customer';
import { clearAddressFormErrors } from 'app/actions/customer';
import { apiRequestPromiseViaDispatch } from 'app/api';
import { zipCodeDisplayFormatter, ZIP_CODE_MASK, PHONE_MASK, phoneDisplayFormatter } from 'app/helpers/formatters';
import { ShippingSchema } from 'app/helpers/validators';
import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import BackNextFooter from './BackNextFooter';
import OutOfServiceAlert from './Payment/OutOfServiceAlert';
import './css/ShippingAddress.scss';
import { Checkbox } from 'app/components/common/formik/Checkbox';
import { P } from 'app/components/common/Typography';

interface ShippingValues extends CustomerShippingAddress {
  phone_number: string;
  receive_sms: boolean;
}

const mapStateToProps = (reduxState: RootState) => {
  const shippingAddress = selectors.selectCustomerShippingAddress(reduxState) || Map();
  const customer = selectors.selectCustomer(reduxState);
  const phone_number = customer.get('phone_number') || '';
  const receive_sms = !!customer.get('receive_sms');
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress.toJS();
  const prev = selectors.selectPreviousStepPath(reduxState);
  const previousStepAction = push(prev);
  const initialFullName = selectors.selectCustomerFullName(reduxState);
  const onSuccessAction = push(selectors.selectNextStepPath(reduxState));
  const initialValues = shippingAddress.merge({
    full_name: initialFullName,
    address_line_1,
    address_line_2,
    city,
    receive_sms,
    state,
    postal_code,
    phone_number: phoneDisplayFormatter(phone_number),
  });
  const initialErrors = reduxState.customer.getIn(['forms', 'update_address_form', 'errors'], {});

  return {
    initialErrors,
    initialValues,
    onSuccessAction,
    previousStepAction,
  };
};
export const ShippingFields: FC<{
  disabled?: boolean;
  labels?: boolean;
  className?: string;
  inputDefaultClassName?: string;
}> = ({ disabled, className, labels = true, inputDefaultClassName = 'mt12' }) => (
  <>
    <div className={inputDefaultClassName}>
      <Input
        id="address_line_1"
        name="address_line_1"
        label={labels ? 'Street Line 1' : null}
        placeholder="Street 1"
        disabled={disabled}
        className={className}
      />
    </div>
    <div className={inputDefaultClassName}>
      <Input
        id="address_line_2"
        name="address_line_2"
        label={labels ? 'Street Line 2' : null}
        placeholder="Street 2"
        disabled={disabled}
        className={className}
      />
    </div>
    <div className={cx(inputDefaultClassName, 'flex')}>
      <div className="flex1">
        <Input
          id="city"
          name="city"
          label={labels ? 'City' : null}
          placeholder="City"
          className={className}
          disabled={disabled}
        />
      </div>
      <div className="flex1">
        <BlackDropdown id="state" name="state" disabled={disabled} className={className} showLabel={labels} />
      </div>
    </div>
    <div className={inputDefaultClassName} />
    <div className={cx(inputDefaultClassName, 'flex')}>
      <div className="flex1">
        <InputMask
          id="postal_code"
          name="postal_code"
          label={labels ? 'ZIP' : null}
          displayFormatter={zipCodeDisplayFormatter}
          mask={ZIP_CODE_MASK}
          inputMode="numeric"
          className={className}
          disabled={disabled}
        />
      </div>
    </div>
  </>
);

interface IShippingStep {
  dispatch: Dispatch;
  previousStepAction: Action;
  initialValues: ImmutableMap<ShippingValues>;
  initialErrors: Record<keyof ShippingValues, string>;
  onSuccessAction: Action;
}
const ShippingStep = ({
  dispatch,
  previousStepAction,
  initialValues,
  initialErrors,
  onSuccessAction,
}: IShippingStep) => {
  const intakeName = useAppSelector(selectors.selectCurrentIntake)?.get('name');
  const redirectPrevious = () => dispatch(previousStepAction);
  const validStates = useAppSelector(selectors.selectValidStates);
  const customerId = useAppSelector(selectors.selectCustomerId);

  const onSubmit = (values, form) =>
    apiRequestPromiseViaDispatch({
      dispatchFn: dispatch,
      path: '/api/commands',
      body: {
        type: 'update_shipping_address',
        user_id: customerId as string,
        params: {
          ...values,
          phone_number: values.phone_number.replace(/\D/g, ''),
          intake: intakeName,
        },
      },
      onErrorFn: (errors) => {
        Object.entries(errors?.parsedJson?.errors).forEach((entry) => {
          const [key, value] = entry;
          form.setFieldError(key, value as string);
        });
      },
      onSuccessAction,
      form,
    });

  return (
    <>
      <TitleWithLine className="mv16 animated-deep-hidden" size="xl">
        Shipping Address
      </TitleWithLine>
      <Formik initialValues={initialValues.toJS()} validationSchema={ShippingSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, values, errors, setFieldValue }: FormikProps<ShippingValues>) => (
          <>
            {values?.state && !validStates.includes(values.state) && <OutOfServiceAlert validStates={validStates} />}
            <PaymentCard className="animated-deep">
              {isSubmitting ? (
                <Spinner isCenter />
              ) : (
                <>
                  <ShippingFields />
                  <div className="mt12 mt16 mb24">
                    <InputMask
                      id="phone_number"
                      name="phone_number"
                      onKeyUp={() => dispatch(clearAddressFormErrors())}
                      label="Mobile Phone Number"
                      displayFormatter={phoneDisplayFormatter}
                      mask={PHONE_MASK}
                      inputMode="tel"
                      initialError={initialErrors.phone_number}
                    />
                  </div>
                  <Checkbox
                    topAligned
                    label={
                      <>
                        <P style={{ fontSize: '10px' }}>
                          I consent to receive text/SMS messages from Maximus, including important updates related to my
                          active subscriptions. I understand I can opt-out at any time by replying STOP to these
                          messages. I understand message and data rates may apply.
                        </P>
                        <P style={{ fontSize: '10px' }}>
                          Please refer to our{' '}
                          <a href="/terms-of-use" target="_blank" style={{ fontSize: '10px' }}>
                            Terms and Privacy Policy
                          </a>
                        </P>
                      </>
                    }
                    testId="receive_sms"
                    name="receive_sms"
                    onChange={(evt) => setFieldValue('receive_sms', evt.target.checked)}
                    checked={values.receive_sms}
                  />
                </>
              )}
              <BackNextFooter
                onNext={handleSubmit}
                nextDisabled={[!isEmpty(initialErrors), !isEmpty(omit(errors, ['api'])), isSubmitting].some(Boolean)}
                onBack={redirectPrevious}
              />
            </PaymentCard>
          </>
        )}
      </Formik>
    </>
  );
};

export default connect(mapStateToProps)(ShippingStep);
