import { P } from 'app/components/common/Typography';
import React from 'react';
import PriceFormatter from './PriceFormatter';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import Divider from '@setproduct-ui/core/Divider';
import GenericOldPrice from 'app/components/customer/steps/Payment/Generic/GenericOldPrice';
import PriceCalculator from 'app/helpers/priceCalculator';
import { AvailableProducts } from 'app/constants/Products';

type Props = {
  productName: AvailableProducts;
  multimonthPeriod: string;
  subProductName: string;
  priceCalculator: PriceCalculator;
  handleChangeProduct?: (e: any) => void;
  handleChangeLab?: (e: any) => void;
};

const MultimonthPaymentHeader = ({
  productName,
  multimonthPeriod,
  priceCalculator,
  subProductName,
  handleChangeProduct,
  handleChangeLab,
}: Props) => {
  const breadcrumbs = (
    <>
      {handleChangeProduct && (
        <span data-testid="change-product" onClick={handleChangeProduct} className="generic_payment_page__change_subs">
          Change Product
        </span>
      )}
      {handleChangeLab && handleChangeProduct && ' | '}
      {handleChangeLab && (
        <span data-testid="change-lab" onClick={handleChangeLab} className="generic_payment_page__change_subs">
          Change Lab Selection
        </span>
      )}
    </>
  );

  return (
    <>
      <ProductHeader
        productName={productName}
        subProductName={subProductName}
        breadcrumbs={breadcrumbs}
        showTitle={true}
      />
      <Divider className="mt24" />
      <GenericOldPrice priceCalculator={priceCalculator} />
      <div className="price_row">
        <h4>{multimonthPeriod} Plan</h4>
        <PriceFormatter price={priceCalculator.monthlyCost()} period="mo" />
      </div>
      <P className="mt12 mb12">
        Covers prescription medications (if qualified), physician consultations, and psychologist health coaching.
      </P>
    </>
  );
};

export default MultimonthPaymentHeader;
