import React, { useContext, useMemo, FC } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import SocialMedia from 'app/components/customer/SocialMedia';
import * as selectors from 'app/selectors/customer';
import IdReview from 'app/components/customer/steps/IdReview';
import ProgressTracker from 'app/components/customer/ProgressTracker';
import cx from 'classnames';
import FailureState from './FailureState';
import ProductHeader from '../ProductHeader';
import { ProductContext } from '../Product';
import { RejectionType } from 'app/constants/enums';

const Content: FC<{ productName: string | null }> = ({ productName }) => {
  const flags = useAppSelector(selectors.selectCustomerFlags);
  const productFlags = useAppSelector((state) => selectors.selectProductFlags(state, productName));
  const hasFollowUp = useAppSelector((state) => selectors.selectHasFollowUp(state, productName));
  const hasActiveSustain = useAppSelector((state) => selectors.selectHasActiveSustain(state, productName));
  const followUpComplete = useAppSelector((state) => selectors.selectFollowUpComplete(state, productName));
  const allSustainsCompleted = useAppSelector((state) => selectors.selectAllSustainsCompleted(state, productName));
  const hasOnboarding = useAppSelector((state) => selectors.selectHasOnboarding(state, productName));
  const onboardingComplete = !hasOnboarding && !hasFollowUp;
  const steps = useAppSelector((state) => selectors.selectCustomerProductOnboardingSteps(state, productName));
  const idReviewStepIncluded = useMemo(
    () =>
      steps
        .map((item) => item.get('name'))
        .toJS()
        .includes('id_review'),
    [steps],
  );

  if (productFlags.includes(RejectionType.LocationRejected)) {
    return <FailureState state={RejectionType.LocationRejected} social={false} />;
  }
  if (productFlags.includes(RejectionType.HealthRejected)) {
    return <FailureState state={RejectionType.HealthRejected} social={false} />;
  }
  const body = (() => {
    if (hasActiveSustain) {
      return <ProgressTracker />;
    }
    if (allSustainsCompleted) {
      return <ProgressTracker />;
    }
    if (hasOnboarding) {
      // 2 replace with PT
      return <ProgressTracker />;
    }
    if (!idReviewStepIncluded && flags.includes('identity_verification_pending')) {
      return <IdReview />;
    }
    if (followUpComplete) {
      return <ProgressTracker />;
    }
    if (hasFollowUp) {
      return <ProgressTracker />;
    }
    if (onboardingComplete) {
      // 1 Replacement for PT
      return <ProgressTracker />;
    }
  })();

  return <div>{body}</div>;
};

const Dashboard = (props) => {
  const productCtx = useContext(ProductContext);
  const productName = productCtx?.selectedProduct;

  return (
    <div
      className={cx(
        'centered flex-column -middle height__full-screen flex-between progress_tracker',
        `selectedProduct-${productName}`,
      )}
    >
      <ProductHeader />
      <Content {...props} productName={productName} />
      <SocialMedia className="pb32" />
    </div>
  );
};

export default Dashboard;
