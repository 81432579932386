import React, { useEffect, useState, useMemo } from 'react';
import Cal, { getCalApi } from '@calcom/embed-react';
import PageHeader from '../../common/PageHeader';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from '../../../selectors/customer';
import { apiRequestUserCommand } from '../../../actions/customer';
import { AvailableProducts } from '../../../constants/Products';
import { CallHistoryMethodAction } from 'connected-react-router';
import { List } from 'immutable';
import Spinner from 'app/components/customer/Spinner';

type Props = {
  onSuccessActionCreator?: (_: any, stateAfterSuccess: any) => CallHistoryMethodAction<[string, any?]>;
};
const ScheduleAppointmentForm = ({ onSuccessActionCreator }: Props) => {
  const customer = useAppSelector(selectCustomer);
  const physician = useMemo(() => customer.get('physician')?.toJS(), [customer]);
  const firstName = customer.get('first_name');
  const email = customer.get('email');
  const dispatch = useAppDispatch();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const isUpdatesPending = useMemo(
    () => customer.get('flags', List()).includes('sensitive_updates_pending'),
    [customer],
  );

  useEffect(() => {
    if (isUpdatesPending) return;

    getCalApi().then((cal) => {
      cal('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          if (submitInProgress) return;

          setSubmitInProgress(true);
          // `data` is properties for the event.
          // `type` is the name of the action
          // `namespace` is the Cal namespace for which the event is fired

          const { data } = e.detail;
          handleSubmit(data);
        },
      });
    });
  }, [isUpdatesPending]);

  const onSubmitSuccess = (_, stateAfterSuccess) => {
    setSubmitInProgress(false);
    if (onSuccessActionCreator) {
      onSuccessActionCreator(_, stateAfterSuccess);
    }
  };

  const handleSubmit = (appointmentData) => {
    const action = apiRequestUserCommand({
      cmdType: 'create_appointment',
      params: {
        product_name: AvailableProducts.King,
        data: JSON.stringify(appointmentData),
      },
      context: { onSubmitSuccess },
    });

    dispatch(action);
  };

  const caldotcomUrl = physician?.caldotcom_url;

  return (
    <div className="centered mb24 updated-design payments">
      <PageHeader title="Schedule Appointment" className="treatment_plan__header mb24 -edged" />
      <div className="content">
        {isUpdatesPending || !caldotcomUrl ? (
          <Spinner isCenter />
        ) : (
          <Cal
            calLink={caldotcomUrl}
            style={{ width: '100%', height: '100%', overflow: 'scroll' }}
            config={{
              layout: 'month_view',
              name: firstName,
              email: email,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ScheduleAppointmentForm;
