import React, { FC, ReactNode } from 'react';
import Radio from 'app/components/common/Radio';
import { P } from 'app/components/common/Typography';
import PriceFormatter from 'app/components/common/PriceFormatter';
import PopularIcon from 'images/most-popular-label.svg';
import ForSexIcon from 'images/for-sex-label.svg';
import '../css/LoverTreatmentPlan.scss';
import { useFormikContext, Field } from 'formik';
import Select from 'react-select';
import cx from 'classnames';
import Divider from '@setproduct-ui/core/Divider';

const RadioLabel: FC<{
  title: string;
  subheader?: ReactNode;
  paragraph?: ReactNode;
  icon?: string;
  dosePrice?: string;
  totalPrice?: number;
}> = ({ title, subheader, paragraph, icon, totalPrice }) => {
  return (
    <div className="">
      <div className="">
        <div className="titles">
          <div className="title">
            <div className="price_row">
              <div dangerouslySetInnerHTML={{ __html: title || '' }}></div>
              {title !== 'As Needed' && (
                <div>
                  <PriceFormatter price={totalPrice} />
                </div>
              )}
              {title === 'As Needed' && <div>&nbsp;</div>}
            </div>
          </div>
          <P>{subheader}</P>
        </div>
      </div>
      <P className="paragraph">{paragraph}</P>
      {icon && <img src={icon} alt="" />}
    </div>
  );
};

const options = [
  {
    label: '4 doses, $59.99/mo, $15.00/dose',
    value: 4,
  },
  {
    label: '8 doses, $109.99/mo, $13.75/dose',
    value: 8,
  },
  {
    label: '12 doses, $159.99/mo, $13.33/dose',
    value: 12,
  },
];

const LoverTreatmentPlan = ({ product }) => {
  const { setFieldValue, values, dirty } = useFormikContext<any>();
  const handleLegacyOption = () => {
    setFieldValue('number_of_doses', null);
    setFieldValue('daily_strength', null);
  };
  const handleDailyStrengthChange = (value) => {
    setFieldValue('number_of_doses', null);
    setFieldValue('daily_strength', value);
  };
  const handleNumberOfDosesRadioClick = () => {
    setFieldValue('number_of_doses', 4);
    setFieldValue('daily_strength', null);
  };

  return (
    <div className="mb32">
      <div className="lover_treatment_plan__option">
        <Radio
          className="no-borders top-aligned"
          onChange={() => handleDailyStrengthChange('2_5_mg')}
          checked={'2_5_mg' === values.daily_strength}
          label={
            <RadioLabel
              paragraph="Recommended for men looking for blood flow enhancements to cognition and for the gym, and bedroom performance. "
              subheader="2.5mg tadalafil + 2.5mg vardenafil"
              title="Daily Use <span class='small'>($3.33/dose)</span>"
              dosePrice="3.33"
              totalPrice={99.99}
            />
          }
        />
      </div>
      <div className="lover_treatment_plan__option">
        <Radio
          className="no-borders top-aligned"
          onChange={() => handleDailyStrengthChange('5_mg')}
          checked={'5_mg' === values.daily_strength}
          label={
            <RadioLabel
              paragraph="Recommended for men looking for blood flow enhancements to cognition and for the gym, and bedroom performance. "
              subheader="5.0mg tadalafil / 5.0mg vardenafil"
              title="Daily Use <span class='small'>($5/dose)</span>"
              dosePrice="5"
              totalPrice={149.99}
              icon={PopularIcon}
            />
          }
        />
      </div>
      <div className="lover_treatment_plan__option">
        <Radio
          checked={!!values.number_of_doses}
          onChange={handleNumberOfDosesRadioClick}
          className="no-borders top-aligned"
          label={
            <RadioLabel
              paragraph="Doses per 30 days"
              subheader="10mg tadalafil / 10mg vardenafil
Reserved for men with severe ED who need a higher dosage to perform their best in the bedroom. Not for daily use."
              title="As Needed"
            />
          }
        />
        <div className="ml36 mt4">
          <Field name="number_of_doses">
            {({ field, form, meta }) => (
              <Select
                className={cx('select', {
                  '-red': meta.error,
                })}
                classNamePrefix="select"
                value={options.find((_) => _.value === field.value || _.value === values.number_of_doses)}
                options={options}
                isSearchable={false}
                onChange={(v) =>
                  form.setFieldValue('number_of_doses', v?.value) && form.setFieldValue('daily_strength', null)
                }
              />
            )}
          </Field>
          <P className="mt4">
            Reserved for men with severe ED who need a higher dosage to perform their best in the bedroom.{' '}
            <span className="bold">Not for daily use.</span>
          </P>
          <img src={ForSexIcon} />
        </div>
      </div>
      {!!product.get('opt_in_choice')?.get('is_legacy') && (
        <div className="lover_treatment_plan__option">
          <Radio
            className="no-borders top-aligned"
            onChange={handleLegacyOption}
            checked={!dirty || (!values.number_of_doses && !values.daily_strength)}
            label={
              <RadioLabel
                subheader="5mg tadalafil + 2mg bremelanotide"
                title="Lover Legacy (Daily Use)"
                dosePrice="5"
                totalPrice={149.99}
              />
            }
          />
        </div>
      )}
      <Divider className="mt24" />
    </div>
  );
};

export default LoverTreatmentPlan;
