import React from 'react';
import { CustomerUserImm, KingIntake, KingProduct } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';
import updateSubscriptionSteps from 'app/utils/updateSubscriptionSteps';
import { AvailableProducts, KingV2LabKitTypes, KingV2SubProducts } from 'app/constants/Products';
import KingV2ProductSelectionForm from 'app/components/customer/steps/Payment/king/KingV2ProductSelectionForm';
import BaseCheckoutHelper from 'app/components/customer/steps/Payment/Generic/BaseCheckoutHelper';
import * as Routes from 'app/constants/Routes';
import KingMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/king/KingMultimonthPlanSelectionForm';
import PriceCalculator from 'app/helpers/priceCalculator';
import KingPriceCalculator from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import GenericSubscriptionSummary from 'app/components/customer/steps/Payment/Generic/GenericSubscriptionSummary';
import GenericSubscriptionSummaryKingV2 from 'app/components/customer/steps/Payment/Generic/GenericSubscriptionSummaryKingV2';

class KingCheckoutHelper extends BaseCheckoutHelper {
  intake: ImmutableMap<KingIntake>;
  product: ImmutableMap<KingProduct>;
  query_params: any;

  constructor(
    customer: CustomerUserImm,
    intake: ImmutableMap<KingIntake>,
    product: ImmutableMap<KingProduct>,
    query_params: any,
  ) {
    super(customer, intake);

    this.intake = intake;
    this.query_params = query_params;
    this.product = product;
  }

  currentProductName = () => AvailableProducts.King;

  firstStep = () => (this.kingV2Offered() ? updateSubscriptionSteps.productStep : updateSubscriptionSteps.planStep);
  productSpecificInitialValues() {
    return {
      selected_king_v2_product: this.initiallySelectedKingV2Product() || KingV2SubProducts.EncloPregnolone,
      multimonth_plan: this.product?.get('opt_in_choice')?.get('multimonth_plan') || 12,
      lab_kit_type: this.isKingV2OralTrt(this.currentSubscriptionProduct())
        ? KingV2LabKitTypes.WalkIn
        : this.customer.get('king_lab_kit_type'),
    };
  }

  multimonthEnabled = () => true;
  showDueTodaySection = true;
  updateSubProductCommandName = 'select_king_v2_product';

  showLabAndDueTodaySection = (params) => this.productChangedToProductTrt(params.selected_king_v2_product);

  redirectTo = (params) =>
    this.productChangedToProductTrt(params.selected_king_v2_product)
      ? Routes.ScheduleAppointment
      : Routes.ManageSubscription;

  renderProductStep = (firstTimeChoice: boolean = false) => (
    <KingV2ProductSelectionForm
      changeProductHandler={this.updateLabTypeChoice}
      firstTimeChoice={firstTimeChoice}
      intake={this.intake}
    />
  );

  renderMultimonthStep = (handleChangeProduct) => (
    <KingMultimonthPlanSelectionForm handleChangeProduct={handleChangeProduct} intake={this.intake} />
  );

  priceCalculator = (values: any, subscription_paid: boolean = false, lab_paid: boolean = false) => {
    const kingPriceCalculator = new KingPriceCalculator(
      values.include_supplement,
      this.supplementsOnAnotherIntake(),
      values.selected_king_v2_product,
      values.multimonth_plan,
      values.use_own_lab,
      this.intake.get('name'),
      this.oralTrtLabNumber(),
      this.crlFeatureEnabledEncloPregWeightLoss(),
      values.lab_kit_type,
      this.isOnboarding(),
      subscription_paid,
      lab_paid,
    );

    return new PriceCalculator(kingPriceCalculator, this.intake.get('discounts'));
  };

  specialDisabledConditions = (values, params) =>
    this.productChangedToProductTrt(values.selected_king_v2_product) &&
    values.use_own_lab &&
    (!values.own_lab_file_uploaded || params.byolFormSubmitted);

  productChanged = (values) => this.currentSubscriptionProduct() !== values.selected_king_v2_product;

  subscriptionSummaryComponent = () =>
    this.kingV2Offered() ? GenericSubscriptionSummaryKingV2 : GenericSubscriptionSummary;

  selectProductStepPath = () => 'king_v2_product_selection';

  updateLabTypeChoice = (kingProductType, setFieldValue) => {
    setFieldValue(
      'lab_kit_type',
      kingProductType === KingV2SubProducts.EncloPregnolone ? KingV2LabKitTypes.AtHomeLabKit : KingV2LabKitTypes.WalkIn,
    );
  };
  currentSubscriptionOptInChoices = () => this.intake.get('current_opt_in_choices');

  currentSubscriptionProduct = () => this.currentSubscriptionOptInChoices()?.get('selected_king_v2_product');

  isKingV2OralTrt = (subProductName) =>
    [KingV2SubProducts.Trt, KingV2SubProducts.EncloPregnoloneTrt].includes(subProductName);

  initiallySelectedKingV2Product = () =>
    this.query_params?.get('oral_trt') ? KingV2SubProducts.Trt : this.currentSubscriptionProduct();

  oralTrtLabNumber = () => this.intake.get('oral_trt_lab_number');

  kingV2Offered = () => this.intake.get('king_v2_offered');

  productChangedToProductTrt = (selectedSubProduct) =>
    this.isKingV2OralTrt(selectedSubProduct) && !this.isKingV2OralTrt(this.currentSubscriptionProduct());

  subProductName = (values) => values.selected_king_v2_product;

  subProductNameForIntakeCheckout = () =>
    (this.kingV2Offered() && this.product?.get('opt_in_choice')?.get('selected_king_v2_product')) ||
    KingV2SubProducts.EncloPregnolone;

  showLabKitOnboarding = () => this.isOnboarding();

  showChangeProductButton = () => this.kingV2Offered();

  multimonthPlansOptions = [
    {
      value: 1,
      label: 'Monthly Plan',
    },
    {
      value: 3,
      label: 'Quarterly Plan',
    },
    {
      value: 12,
      label: 'Yearly Plan',
    },
  ];

  subscriptionPaidSuccessfuly = () => this.intake.get('customer_subscription_successful');
}

export default KingCheckoutHelper;
