import { createModel } from '@rematch/core';
import { fromJS, Map, List } from 'immutable';
import { Cmd, loop } from 'redux-loop';
import { apiRequest, fileUpload } from 'app/api';
import { updateThread, uniqBy, messageKey } from 'app/helpers/reducerHelpers';
import { apiRequestFailure, apiRequestSuccess } from 'app/models/admin/commandHelpersAdmin';

import { selectUser } from 'app/selectors/admin';
import { CustomerUserImm, HistoryItem } from 'app/types/admin/customerUser';

const dedupeBy = (keyFn, list) =>
  list
    .groupBy(keyFn)
    .map((x) => x.first())
    .toList();

export const admin = createModel()({
  state: Map({
    connectivity: 'offline',
    openModal: undefined,
    mostRecentApiProblem: undefined as string | undefined,
    users: Map<CustomerUserImm, unknown>(),
    user_history: Map<HistoryItem, string>(),
    support_messages: List(),
    gReqId: 0,
    messagesPollingId: undefined as any,
  }),
  reducers: {
    'clearApiProblem'(state) {
      return state.delete('mostRecentApiProblem');
    },
    'API_REQUEST_FAILED': (state) => state.set('connectivity', 'offline'),
    'API_REQUEST_SUCCESS'(state, action) {
      const succ = state.set('connectivity', 'online');
      let newState = succ;
      let entities = fromJS((action.json.entity && [action.json.entity]) || action.json.entities || []);
      if (action.json.more_entities) {
        entities = entities.concat(fromJS(action.json.more_entities));
      }
      if (entities) {
        newState = fromJS(entities)
          .reduce((oldState, entity) => {
            switch (entity.get('entity_type')) {
              case 'private_message':
                return updateThread(oldState, entity).update('support_messages', (support_messages) => {
                  if (entity.get('topic')) {
                    return support_messages.push(entity);
                  }

                  return support_messages;
                });
              case 'customer':
                return oldState.setIn(['users', entity.get('id')], entity);
              case 'login_record': {
                const loginHistory = oldState.getIn(['loginHistory', entity.get('user_id')]) || List();

                return oldState.setIn(
                  ['loginHistory', entity.get('user_id')],
                  dedupeBy((x) => x.get('id'), loginHistory.push(entity)),
                );
              }
              case 'lab_order': {
                const labOrders = oldState.getIn(['labOrders', entity.get('user_id')]) || List();

                return oldState.setIn(
                  ['labOrders', entity.get('user_id')],
                  dedupeBy((x) => x.get('id'), labOrders.push(entity)),
                );
              }
              case 'discount': {
                const discounts = oldState.getIn(['users', entity.get('user_id'), 'discounts']) || List();
                return oldState.setIn(
                  ['users', entity.get('user_id'), 'discounts'],
                  dedupeBy((x) => x.get('id'), discounts.push(entity)),
                );
              }
              case 'admin_report': {
                return oldState.updateIn(['reports'], (rr) => rr.push(entity));
              }
              case 'admin':
              case 'berbix_tokens':
              case 'tombstone':
                return oldState; // nothing for now

              default:
                return oldState;
            }
          }, succ)
          .update('support_messages', (messages) => uniqBy(messageKey, messages));
      } else {
        throw new Error('omg no');
      }

      return newState;
    },
    'API_REQUEST_ERRORS'(state, action) {
      let errorMsg = action.json?.error;
      let error = `Server Error ${action.status}`;
      if (typeof errorMsg === 'string') {
        error += `: ${errorMsg}`;
      } else {
        errorMsg = action.json?.errors?.base;
        if (typeof errorMsg === 'string') {
          error += `: ${errorMsg}`;
        } else {
          if (typeof action.json?.errors === 'object') {
            const errors: string[] = [];
            for (const key of Object.keys(action.json?.errors)) {
              const e = action.json?.errors[key];
              if (Array.isArray(e)) {
                errors.push(`${key}: ${e.join(', ')}`);
              } else {
                errors.push(`${key}: ${e}`);
              }
            }
            if (errors.length > 0) {
              error += `\r\n${errors.join('\r\n')}`;
            }
          }
        }
      }

      return state.set('connectivity', 'online').set('mostRecentApiProblem', error);
    },
    'API_REQUEST_PROBLEMS'(state, action) {
      if (action.form) {
        return state.set('connectivity', 'online');
      }
      if (action.context && action.context.cmdType) {
        const message = `${action.context.cmdType}: ${JSON.stringify(action.json.errors)}`;

        return state.set('mostRecentApiProblem', message);
      }
      const message = `${JSON.stringify(action.json)}`;

      return state.set('mostRecentApiProblem', message);
    },
    'setOpenModal': (state, name) => state.set('openModal', name),
    '@@admin/MESSAGE_FETCHING_STARTED': (state, action) => state.set('messagesPollingId', action.timerId),
    '@common/INIT_USERS': (state, action) => state.merge(fromJS(action)) as any,
    '@@router/LOCATION_CHANGE': (state, payload) => {
      const path = payload.location.pathname;
      const regex =
        /^\/admin\/users\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/g;
      const found = regex.exec(path);
      const userId = found && found.slice(-1);
      if (userId) {
        const reqId = (state.get('gReqId') as unknown as number) || 0;
        const reqId2 = reqId + 1;
        const nextReqId = reqId2 + 1;
        const cmd1 = Cmd.run(apiRequest, {
          successActionCreator: apiRequestSuccess,
          failActionCreator: apiRequestFailure,
          args: [reqId, 'GET', `/api/customers/${userId}`, null],
        });
        const cmd2 = Cmd.run(apiRequest, {
          successActionCreator: apiRequestSuccess,
          failActionCreator: apiRequestFailure,
          args: [reqId2, 'GET', `/api/private_messages?user_id=${userId}&thread=support`],
        });

        return loop(state.set('gReqId', nextReqId), Cmd.list([cmd1, cmd2]));
      }

      return loop(
        state.delete('messagesPollingId'),
        Cmd.clearInterval(state.get('messagesPollingId') as unknown as number),
      ) as unknown as any;
    },
    'updateHistory'(state, { userId, data }) {
      return state.setIn(['user_history', userId], fromJS(data));
    },
    'updateStripeInfo'(state, { userId, data }) {
      return state.setIn(['user_stripe_info', userId], fromJS(data));
    },
  },
  effects: (dispatch) => ({
    async apiRequestCommand(action) {
      const result = await apiRequest(42, 'POST', '/api/commands', {
        type: action.cmdType,
        params: action.params,
      })
        .then(apiRequestSuccess)
        .catch(apiRequestFailure);
      dispatch(result);

      if (result?.type !== 'admin/API_REQUEST_SUCCESS') {
        throw result.payload;
      }
    },
    async reloadUser(action, state) {
      const userId = selectUser(state)?.get('id');
      if (userId === undefined) return;

      const result = await apiRequest(43, 'get', `/api/customers/${userId}/nudge`)
        .then(apiRequestSuccess)
        .catch(apiRequestFailure);
      dispatch(result);

      if (result?.type !== 'admin/API_REQUEST_SUCCESS') {
        throw result.payload;
      }

      dispatch.admin.fetchUserHistory({ userId });
    },
    async apiRequestUserCommand(action) {
      const result = await apiRequest(
        42,
        'POST',
        '/api/commands',
        {
          type: action.cmdType,
          user_id: action.userId,
          params: action.params,
        },
        action.context,
      )
        .then(apiRequestSuccess)
        .catch(apiRequestFailure);
      dispatch(result);

      if (result?.type !== 'admin/API_REQUEST_SUCCESS') {
        throw result.payload;
      } else if (result.payload && result.payload.json) {
        if (result.payload.json.entities) {
          result.payload.json.entities.forEach((entity) => {
            if (entity.entity_type === 'customer') {
              dispatch.admin.fetchUserHistory({ userId: entity.id });
            }
          });
        }
        if (result.payload.json.entity) {
          const { entity } = result.payload.json;
          if (entity.entity_type === 'customer') {
            dispatch.admin.fetchUserHistory({ userId: entity.id });
          }
        }

        return result.payload.json?.entities || [result.payload.json?.entity];
      }
    },
    async apiRequestUserCommandWithHandledCatch(action) {
      return dispatch.admin.apiRequestUserCommand(action);
    },
    async deleteUserButtonClicker(action) {
      const result = await apiRequest(42, 'DELETE', `/api/customers/${action.userId}`)
        .then(() => {
          (window as Window).location = '/admin';
        })
        .catch(apiRequestFailure);
      if (result) {
        dispatch(result);
      }
    },
    async sendPrivateMessage(action) {
      const result = await apiRequest(
        42,
        'POST',
        '/api/commands',
        {
          type: 'send_private_message',
          user_id: action.userId,
          params: action.values,
        },
        { formReset: action.formReset, form: action.form },
      )
        .then(apiRequestSuccess)
        .catch(apiRequestFailure);
      dispatch(result);
    },
    async fetchSupportMessages() {
      const result = await apiRequest(42, 'GET', `/api/private_messages?thread=support`)
        .then(apiRequestSuccess)
        .catch(apiRequestFailure);
      dispatch(result);
    },
    async fetchUserHistory({ userId }) {
      const result = await apiRequest(43, 'GET', `/api/customers/${userId}/history`)
        .then(apiRequestSuccess)
        .catch(apiRequestFailure);

      if (result?.type === 'admin/API_REQUEST_SUCCESS') {
        dispatch.admin.updateHistory({
          userId,
          data: result.payload.json.data,
        });

        return result.payload;
      }

      dispatch(result);
    },
    async fileUpload(action) {
      const result = await fileUpload(42, '/api/files', action.data, {
        form: 'upload_file_form',
      })
        .then(apiRequestSuccess)
        .catch(apiRequestFailure);
      dispatch(result);
    },
    async labOrderResultSubmitted(action) {
      const result = await fileUpload(42, '/api/lab_order_results', action.data, { form: 'upload_lab_result_form' })
        .then(apiRequestSuccess)
        .catch(apiRequestFailure);
      dispatch(result);
    },
    async removeLabOrderResult(action) {
      const result = await apiRequest(42, 'DELETE', `/api/lab_order_results/${action.id}`)
        .then(() => {
          (window as Window).location = '/admin';
        })
        .catch(apiRequestFailure);
      if (result) {
        dispatch(result);
      }
    },
    async userImportUpload(action) {
      const result = await fileUpload(42, `/api/customers/import`, action.data, {
        form: 'upload_user_import_form',
      })
        .then(apiRequestSuccess)
        .catch(apiRequestFailure);
      dispatch(result);
    },
  }),
});
