import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import React, { useMemo, useState } from 'react';
import { P } from 'app/components/common/Typography';
import { PrimaryButton } from 'app/components/common/Button';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import WhiteButton from 'app/components/common/WhiteButton';
import { Formik, useFormikContext } from 'formik';
import Alert from 'app/components/common/Alert';
import { ShippingSchemaWithoutFullName } from 'app/helpers/validators';
import { selectCustomerId, selectCustomerShippingAddress, selectValidStates } from 'app/selectors/customer';
import { apiRequestPromise } from 'app/api';
import { apiRequestFailure, apiRequestSuccess } from 'app/helpers/commandHelpers';
import OutOfServiceAlert from '../OutOfServiceAlert';
import SetProductDialog from '@setproduct-ui/core/Dialog';

const GenericShippingCard = () => {
  const validStates = useAppSelector(selectValidStates);
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);
  const user_id = useAppSelector(selectCustomerId);
  const [openModalShippingAddress, setOpenModalShippingAddress] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useAppDispatch();
  const { setFieldValue } = useFormikContext();
  const shippingAddressValues = useMemo(() => shippingAddress.toJS(), [shippingAddress]);
  const { address_line_1, address_line_2, city, state: shippingState, postal_code } = shippingAddressValues;
  const line1 = `${address_line_1}.`;
  const line2 = `${address_line_2}.`;
  const line3 = `${city}, ${shippingState} ${postal_code}`;
  const line2Component = (
    <P type="body_bold" className="mh16" data-testid="line2">
      {line2}
    </P>
  );

  const onFormSubmitted = async (values) => {
    const { address_line_1, address_line_2, city, state, postal_code } = values;
    const params = {
      address_line_1,
      address_line_2,
      city,
      state,
      postal_code,
    };

    try {
      const resp = await apiRequestPromise('POST', '/api/commands', {
        type: 'update_shipping_address',
        user_id,
        params: { ...params },
      });
      setExpanded(false);
      setShowAlert(true);
      dispatch(apiRequestSuccess(resp));
      setOpenModalShippingAddress(false);
    } catch (error) {
      const message =
        error instanceof Error ? error.message : 'An error occurred (GenericShippingCard#onFormSubmitted)';
      dispatch(apiRequestFailure({ message, reqId: 42, request: {} }));
    }

    for (const [k, v] of Object.entries(params)) {
      setFieldValue(k, v);
    }
  };
  const outOfService = !validStates.includes(shippingState);

  const handleClick = () => {
    if (line1) {
      setOpenModalShippingAddress(true);
    } else {
      setExpanded(true);
    }
  };

  const handleClose = () => {
    setExpanded(false);
    setOpenModalShippingAddress(false);
  };

  const ShippingForm = () => (
    <Formik
      initialValues={shippingAddressValues}
      validationSchema={ShippingSchemaWithoutFullName}
      onSubmit={onFormSubmitted}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="payment_card">
          <div className="payment_method_info">
            <ShippingFields />
            <hr />
            <div className="flex">
              <WhiteButton onClick={handleClose} text="Cancel" />
              <PrimaryButton onClick={() => handleSubmit()} text="Save" disabled={isSubmitting} />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );

  return (
    <>
      <TitleWithLine className="mb16 mt52" size="xl">
        Shipping
      </TitleWithLine>
      {showAlert && (
        <Alert onClose={() => setShowAlert(false)} className="mb24">
          Shipping address updated successfully
        </Alert>
      )}
      {outOfService && <OutOfServiceAlert validStates={validStates} />}
      <PaymentCard>
        <div className="payment_method_info">
          <div className="flex">
            <div className="flex1">
              <P className="mt12 bold generic_payment_page__shipping_address_title">Shipping Address</P>
              <P type="body_bold" className="mt12">
                {line1}
              </P>
              {address_line_2 && line2Component}
              <P type="body_bold">{line3}</P>
              {!expanded && (
                <WhiteButton
                  onClick={handleClick}
                  className="payment_button mt24 generic_payment_page__shipping_address_update"
                  text="update address"
                />
              )}
            </div>
          </div>
          {expanded && <ShippingForm />}
        </div>
      </PaymentCard>

      <SetProductDialog
        isOpen={openModalShippingAddress}
        text={<ShippingForm />}
        onClose={() => setOpenModalShippingAddress(false)}
        className="manage_subscription__cancel_modal generic_payment_page__modal align-left maximized"
      />
    </>
  );
};

export default GenericShippingCard;
