import PageHeader from 'app/components/common/PageHeader';
import React, { useRef, useState } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import {
  selectCurrentIntake,
  selectCurrentIntakeProduct,
  selectCustomer,
  selectCustomerShippingAddress,
  selectLatestPaymentMethodErrorMessage,
} from 'app/selectors/customer';
import { useFormikContext, FormikProps } from 'formik';
import 'app/components/customer/steps/Payment/css/Payment.scss';
import 'app/components/customer/steps/Payment/css/GenericPaymentPage.scss';
import GenericShippingCard from 'app/components/customer/steps/Payment/Generic/GenericShippingCard';
import GenericPaymentMethod from 'app/components/customer/steps/Payment/Generic/GenericPaymentMethod';
import * as selectors from 'app/selectors/customer';
import GenericPaymentHeader from 'app/components/customer/steps/Payment/Generic/GenericPaymentHeader';
import { push } from 'connected-react-router/immutable';
import GenericDueToday from 'app/components/customer/steps/Payment/Generic/GenericDueToday';
import GenericSelfPay from 'app/components/customer/steps/Payment/Generic/GenericSelfPay';
import SubscriptionWidget from 'app/components/customer/steps/Payment/Generic/SubscriptionWidget';
import { MultiMonthPlanMapping, KingV2SubProducts, KingV2LabKitTypes } from 'app/constants/Products';
import { apiRequestUserCommand } from 'app/actions/customer';
import GenericShippingForm from './GenericShippingForm';
import { useDispatch } from 'react-redux';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';

type Props = {
  isOnboarding: boolean;
  errorMessage?: string;
  setOptInParams?: (e: any) => void;
};

const GenericPaymentForm = ({ errorMessage, setOptInParams, isOnboarding }: Props) => {
  const dispatch = useDispatch();
  const { values, handleSubmit, isSubmitting, isValid, setFieldValue } = useFormikContext<{
    include_supplement: boolean;
    use_own_lab: boolean;
    lab_kit_type: KingV2LabKitTypes;
    own_lab_file_uploaded: boolean;
    selfPay: boolean;
    daily_strength: string;
    drug_variant: string;
    number_of_doses: 4 | 12 | 8;
    multimonth_plan: 1 | 3 | 6 | 12;
    selected_king_v2_product: KingV2SubProducts;
  }>();
  const { selfPay, multimonth_plan } = values;
  const customer = useAppSelector(selectCustomer);
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, currentProductName));
  const intake = useAppSelector(selectCurrentIntake);

  const subscriptionPaidSuccessfuly = intake.get('customer_subscription_successful');
  const labPaidSuccessfuly = intake.get('customer_lab_payment_successful');

  const checkoutHelper = createCheckoutHelper(intake, customer, product);
  const priceCalculator = checkoutHelper.priceCalculator(values, subscriptionPaidSuccessfuly, labPaidSuccessfuly);

  const latestPaymentMethodErrorMessage = useAppSelector(selectLatestPaymentMethodErrorMessage);

  const subProductName = checkoutHelper.subProductNameForIntakeCheckout(product);
  const multimonthPeriod = MultiMonthPlanMapping[multimonth_plan];

  const paymentErrorMessage = errorMessage || customer.get('payment_error_message') || latestPaymentMethodErrorMessage;
  const currentPaymentMethod = customer.get('payment_method');

  const showLabKit = checkoutHelper.showLabKitOnboarding();
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);

  const [shippingFormValid, setShippingFormValid] = useState(false);

  const onMultimonthPlanChange = (value) => {
    const action = apiRequestUserCommand({
      cmdType: 'select_multimonth_plan',
      params: { multimonth_plan: value, intake: intake.get('name') },
      context: {},
    });
    dispatch(action);
  };

  const shippingFormRef = useRef<FormikProps<any>>(null);
  const onSubmit = (applePayPaymentMethod: any = null) => {
    if (!shippingAddress && !shippingFormValid) return;

    if (shippingFormRef.current?.dirty) {
      shippingFormRef.current.submitForm();
    }

    if (applePayPaymentMethod?.paymentMethodId) {
      setFieldValue('applePayPaymentMethod', applePayPaymentMethod);
    }

    handleSubmit();
  };

  const renderDueToday = () => {
    if (!checkoutHelper.showDueTodaySection) return;

    return (
      <GenericDueToday
        multimonthPeriod={multimonthPeriod}
        isOnboarding={isOnboarding}
        priceCalculator={priceCalculator}
        showLabKit={showLabKit}
        intake={intake}
      />
    );
  };
  const [productPharmacyValid, setProductPharmacyValid] = useState(true);

  return (
    <div className="generic_payment_page centered updated-design payments">
      <PageHeader title="Checkout" className="treatment_plan__header mb24 -edged" />
      <div className="content">
        <GenericPaymentHeader
          currentProductName={currentProductName}
          multimonthEnabled={checkoutHelper.multimonthEnabled()}
        />
        <SubscriptionWidget
          currentProductName={currentProductName}
          product={product}
          priceCalculator={priceCalculator}
          goToSelectProductPage={() => dispatch(push(checkoutHelper.selectProductStepPath()))}
          goToSelectLabPage={() => dispatch(push('lab_selection'))}
          multimonthPeriod={multimonthPeriod}
          multimonthPlan={multimonth_plan}
          isOnboarding={isOnboarding}
          supplementsOnAnotherIntake={checkoutHelper.supplementsOnAnotherIntake()}
          multimonthEnabled={checkoutHelper.multimonthEnabled()}
          checkoutHelper={checkoutHelper}
          onMultimonthPlanChange={onMultimonthPlanChange}
          productChanged={false}
          subProductName={subProductName}
          activeProductWithSupplementName={checkoutHelper.activeProductWithSupplementName()}
        />
        {!shippingAddress && (
          <GenericShippingForm
            shippingFormRef={shippingFormRef}
            productPharmacyValid={productPharmacyValid}
            setProductPharmacyValid={setProductPharmacyValid}
            setShippingFormValid={setShippingFormValid}
          />
        )}
        {renderDueToday()}
        {!!shippingAddress && <GenericShippingCard />}
        <GenericSelfPay />
        <GenericPaymentMethod
          paymentErrorMessage={paymentErrorMessage}
          currentPaymentMethod={currentPaymentMethod}
          setOptInParams={setOptInParams}
          onCheckout={onSubmit}
          priceCalculator={priceCalculator}
          disabled={
            isSubmitting || !isValid || !selfPay || !productPharmacyValid || (!shippingAddress && !shippingFormValid)
          }
        />
      </div>
    </div>
  );
};

export default GenericPaymentForm;
