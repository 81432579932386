import React from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntake, selectCustomer, selectCustomerProduct, selectNextStepPath } from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import { Formik } from 'formik';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import PageHeader from 'app/components/common/PageHeader';
import { useDispatch } from 'react-redux';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';

const MultimonthPlanSelectionStep = () => {
  const intake = useAppSelector(selectCurrentIntake);
  const customer = useAppSelector(selectCustomer);
  const productName = intake.get('product_name');
  const product = useAppSelector((state) => selectCustomerProduct(state, productName));
  const dispatch = useDispatch();

  const checkoutHelper = createCheckoutHelper(intake, customer, product);

  const goToSelectProductPage = push(checkoutHelper.selectProductStepPath());
  const onSuccessAction = push(useAppSelector(selectNextStepPath));

  const initialValues = checkoutHelper.productSpecificInitialValues();
  const handleChangeProduct = () => dispatch(goToSelectProductPage);

  const onSubmit = (params: any) => {
    const action = apiRequestUserCommand({
      cmdType: 'select_multimonth_plan',
      params: { ...params, intake: intake.get('name') },
      context: { onSuccessAction },
    });
    dispatch(action);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <div className="centered mb24 updated-design payments">
          <PageHeader title="Choose Your Plan" className="treatment_plan__header mb24 -edged" />
          <div className="content">
            {checkoutHelper.renderMultimonthStep(handleChangeProduct)}
            <BackNextFooter
              noBack={true}
              onNext={handleSubmit}
              testId="submit-plan-selection"
              horizontalPadding={false}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default MultimonthPlanSelectionStep;
