import React, { useState, useMemo, FC } from 'react';
import { Formik, Form } from 'formik';
import Question from 'app/constants/Question';
import checkboxCheckedImage from 'images/CheckedBoxDefault.svg';
import checkboxUncheckedImage from 'images/UncheckedBoxDefault.svg';
import { getComponentForQuestion, getDefaultValue } from 'app/components/customer/questions/helpers';
import { getIntakeSchema } from 'app/helpers/validators';
import ProtocolHeader from 'app/components/common/Typography/ProtocolHeader';
import { AvailableProducts } from 'app/constants/Products';

const INITIAL_STEPS = ['sex', 'erection', 'ed_scenario', 'ed_start'];
const ONLY_MEN_STEPS = ['erection', 'ed_scenario', 'ed_start'];

const getNextStep = (steps, currentIndex, values) => {
  const nextStep = steps[currentIndex + 1];

  if (!nextStep) {
    return null;
  }

  if (values.erection === 'never' && ['ed_scenario', 'ed_start'].includes(nextStep)) {
    return getNextStep(steps, currentIndex + 1, values);
  }

  return nextStep;
};

const Step0 = ({ onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(INITIAL_STEPS[0]);
  const questions = useMemo(
    () => INITIAL_STEPS.reduce<Map<any, any>>((o, q) => o.set(q, new Question(q)), new Map()),
    [INITIAL_STEPS],
  );
  const [initialValues, setInitialValues] = useState(
    INITIAL_STEPS.reduce((o, q) => ({ ...o, [q]: getDefaultValue({ question: questions.get(q) }) }), {}),
  );

  const question = questions.get(currentStep);
  const IntakeSchema = getIntakeSchema(question);
  const Component = getComponentForQuestion({ question }) as FC<{
    question: any;
    name: string;
    checkedImage: typeof checkboxCheckedImage;
    uncheckedImage: typeof checkboxUncheckedImage;
    contained: boolean;
  }>;

  const submitNextStep = (values) => {
    let currentStepIndex = INITIAL_STEPS.indexOf(currentStep);
    let nextStep = getNextStep(INITIAL_STEPS, INITIAL_STEPS.indexOf(currentStep), values);
    if (!nextStep) {
      const clearValues = Object.entries(values).reduce((o, [k, v]) => {
        if (v !== '') {
          o[k] = v;
        }

        return o;
      }, {});
      onSubmit(clearValues);
    } else {
      while (values['sex'] === 'f' && ONLY_MEN_STEPS.includes(nextStep)) {
        currentStepIndex += 1;
        nextStep = getNextStep(INITIAL_STEPS, currentStepIndex, values);
      }
      setInitialValues(values);
      setCurrentStep(nextStep);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitNextStep} validationSchema={IntakeSchema} enableReinitialize>
      {() => (
        <Form className="registration-flow registration-flow_lover">
          <ProtocolHeader protocol={AvailableProducts.Lover} />
          <h2 className="title title_sm">{question.sentence}</h2>
          {question.data.description && <p className="pre-wrap mt24 mb24">{question.data.description}</p>}
          <div className="component">
            <Component
              question={question}
              name={currentStep}
              checkedImage={checkboxCheckedImage}
              uncheckedImage={checkboxUncheckedImage}
              contained={false}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step0;
