import { P } from 'app/components/common/Typography';
import { AvailableProducts, KingV2LabKitTypes } from 'app/constants/Products';
import PriceFormatter from 'app/components/common/PriceFormatter';
import { SecondaryButton } from 'app/components/common/Button';
import React from 'react';
import heic2any from 'heic2any';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomerState } from 'app/selectors/customer';

const LabOnboardingWidget = ({
  product_name,
  setOpenModalAtHomeLab,
  use_own_lab,
  lab_kit_type,
  setFieldValue,
  own_lab_file_uploaded,
  inputFile,
  setOwnLabFile,
  setOpenModalOwnLab,
  kingV2OralTrt,
  priceCalculator,
  byolFormSubmitted,
}) => {
  const QUEST_FORBIDDEN_STATES = ['NY', 'NJ'];
  const atHomeLabPrice = priceCalculator.atHomeLabPrice() / 100;
  const walkInLabPrice = priceCalculator.walkInLabPrice() / 100;
  const customerState = useAppSelector(selectCustomerState);

  const handleLabTypeChange = (evt) => {
    setFieldValue('lab_kit_type', evt.target.value);
    if (evt.target.value === KingV2LabKitTypes.UseYourOwnLab) {
      setFieldValue('use_own_lab', true);
    } else {
      setFieldValue('use_own_lab', false);
    }
  };

  const showQuestLabs =
    product_name === AvailableProducts.King && (kingV2OralTrt || !QUEST_FORBIDDEN_STATES.includes(customerState));

  const onImageChange = ({ target }) => {
    const file = target.files?.[0];
    target.value = '';

    if (file) {
      if (file.type.toLowerCase() === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
        heic2any({ blob: file, toType: 'image/jpeg', quality: 0.7 }).then((newImage) => {
          setOwnLabFile(newImage);
        });
      } else {
        setOwnLabFile(file);
      }
    }
  };

  return (
    <>
      <P className="mb12">
        {product_name === AvailableProducts.King &&
          !kingV2OralTrt &&
          'Lab testing is required in the first two months of treatment. The first to assess your hormone levels before treatment, and the second to track your progress after treatment.'}
        {product_name === AvailableProducts.King &&
          kingV2OralTrt &&
          'Lab testing is required in month 1, month 2, and month 3 to test hormone levels at baseline & follow-up (during treatment).'}
        {product_name === AvailableProducts.Magician && (
          <>
            Since you indicated during the medical questionnaire you have Hypertension and/or Kidney Disease, your
            doctor will require you to provide lab work within 60 days of starting treatment. <br />
            <br /> You can choose to provide your own lab work today or use Maximus' convenient at-home lab testing.{' '}
            <br />
            <br /> Don’t worry – your doctor will not delay writing your initial prescription. Your doctor will review
            your medical questionnaire responses and place your order within 48 hours (Mon-Fri) if qualified.{' '}
          </>
        )}
      </P>
      {!kingV2OralTrt && (
        <>
          <div className="price_row mt24 mb12">
            <div className="minW30">
              <input
                type="radio"
                data-testid="use-lab-kit"
                name="lab_kit_type"
                value={KingV2LabKitTypes.AtHomeLabKit}
                className="radio_button"
                checked={!use_own_lab && lab_kit_type === KingV2LabKitTypes.AtHomeLabKit}
                onChange={handleLabTypeChange}
              />
            </div>
            <P className="bold">At-Home Lab Kits</P>
            <PriceFormatter price={atHomeLabPrice} />
          </div>
          <div className="display_flex">
            <div className="minW30" />
            <P className="mb12">
              Maximus uses CLIA-certified lab tests that ship straight to your door. The tests are painless and do not
              require finger-pricking. Results are available FAST (typically within four business days).{' '}
              <a href="#" onClick={() => setOpenModalAtHomeLab(KingV2LabKitTypes.AtHomeLabKit)}>
                Full details
              </a>
            </P>
          </div>
        </>
      )}
      {showQuestLabs && (
        <>
          <div className="price_row mt24 mb12">
            <div className="minW30">
              <input
                type="radio"
                data-testid="use-lab-kit-walk-in"
                name="lab_kit_type"
                value={KingV2LabKitTypes.WalkIn}
                className="radio_button"
                checked={!use_own_lab && lab_kit_type === KingV2LabKitTypes.WalkIn}
                onChange={handleLabTypeChange}
              />
            </div>
            <P className="bold">
              Walk-in <span className="desc">(Quest Labs)</span>
            </P>
            <PriceFormatter price={walkInLabPrice} />
          </div>
          <div className="display_flex">
            <div className="minW30" />
            <P className="mb12">
              We’ve partnered with Quest Labs to make it easy. We’ll provide you with a pre-paid requisition form that
              you can take to your nearest Quest Lab at your convenience.{' '}
              <a href="#" onClick={() => setOpenModalAtHomeLab(KingV2LabKitTypes.WalkIn)}>
                Full details
              </a>
            </P>
          </div>
        </>
      )}
      <div className="price_row mt24 mb12">
        <div className="minW30">
          <input
            type="radio"
            data-testid="use-own-lab"
            name="use_own_lab"
            className="radio_button"
            checked={use_own_lab || lab_kit_type === KingV2LabKitTypes.UseYourOwnLab} // for old customers use_own_lab still may be set
            value={KingV2LabKitTypes.UseYourOwnLab}
            onChange={handleLabTypeChange}
          />
        </div>
        <P className="bold">Use Your Own Lab Test</P>
        <div />
      </div>
      <div className="display_flex">
        <div className="minW30" />
        <div>
          <P className="mb12">
            {product_name === AvailableProducts.King &&
              'You may upload your own lab test results free of charge as long as they meet clinical requirements.'}
            {product_name === 'magician' &&
              'You will be asked to use the same lab vendor and panel for your follow-up lab test (completed about 90 days after starting treatment, or as required by your licensed Maximus doctor). '}
            <a href="#" onClick={() => setOpenModalAtHomeLab(KingV2LabKitTypes.UseYourOwnLab)}>
              {' '}
              Full details
            </a>
          </P>
          {use_own_lab && (
            <>
              {!own_lab_file_uploaded || !byolFormSubmitted ? (
                <div className="byol-form-warning">
                  This option requires lab results be uploaded in order to check out.
                </div>
              ) : (
                <div className="byol-form-success">Successfully uploaded</div>
              )}
              <div className="photo_upload">
                <SecondaryButton
                  data-testid="own-lab-modal-open"
                  className="outlined-button mt20"
                  text="UPLOAD LAB RESULTS"
                  onClick={() => setOpenModalOwnLab(true)}
                  disabled={!use_own_lab}
                  size={'small'}
                />
              </div>
              <input
                ref={inputFile}
                type="file"
                id="file"
                style={{ display: 'none' }}
                accept="image/*, application/pdf"
                onChange={onImageChange}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LabOnboardingWidget;
