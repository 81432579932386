/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { ReactSVG } from 'react-svg';
import { push } from 'connected-react-router/immutable';
import dayjs from 'dayjs';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

import {
  ONBOARDING_TYPES,
  selectAllErxShipmentDates,
  selectCustomer,
  selectCustomerDismissedNotifications,
  selectCustomerId,
  selectCustomerLabOrders,
  selectCustomerQuestionnaire,
  selectFurthestProductLastIntakeStepPath,
  selectLastProductIntake,
  selectProductLabOrderResults,
  selectStrictlyProductIntakeStepsByLastIntake,
} from 'app/selectors/customer';
import * as actions from 'app/actions/customer';
import * as Routes from 'app/constants/Routes';

import TitleWithIcon from 'app/components/customer/ProgressTracker/common/TitleWithIcon';
import { PrimaryButton } from 'app/components/common/Button';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';

import TrackerNotification, {
  LabOrderResult,
  Notification,
  NotificationType,
} from 'app/components/customer/ProgressTracker/common/TrackerNotification';

import DottedClock from 'images/progress-tracker/dotted-clock.svg';
import AlarmIcon from 'images/progress-tracker/alarm.svg';
import IconArrowUp from 'images/Icon-Arrow-Up.svg';
import { AvailableProducts, KingV2LabKitTypes } from 'app/constants/Products';

import { ProductContext } from '../Product';
import './css/UpNext.scss';
import { ImmutableMapping } from 'app/types/admin';
import { BaseIntake, BaseKingIntake } from 'app/types/admin/customerUser';
import * as selectors from 'app/selectors/customer';
import Cancelled from '../dashboard/Cancelled';
import { useDispatch } from 'react-redux';

type Filters = 'all' | 'dismissed' | 'new';

export const collectNotifications = (
  isDismissed: (notificationName: string) => boolean,
  currentIntake: ImmutableMapping<BaseIntake | BaseKingIntake>,
  labInfo: {
    shipped_at: string | null;
  },
  labOrderResults: LabOrderResult[],
  erxShipmentDates: {
    date: string;
    intake: string;
  }[],
) => {
  const result: Array<Notification> = [];

  if (labInfo?.shipped_at) {
    result.push({
      type: NotificationType.LabInfo,
      date: labInfo.shipped_at,
      dismissed: isDismissed(`lab-shipped-${currentIntake?.get('name')}`),
      obj: labInfo,
    });
  }

  labOrderResults.forEach((labOrderResult) => {
    result.push({
      type: NotificationType.LabOrderResult,
      date: labOrderResult.get('date'),
      dismissed: isDismissed(`lab-result-${labOrderResult.get('file').get('blob_id')}`),
      obj: labOrderResult,
    });
  });
  erxShipmentDates.forEach((erxShipmentDate) => {
    result.push({
      type: NotificationType.ErxShipmentDate,
      date: erxShipmentDate.date,
      dismissed: isDismissed(`erx-${erxShipmentDate.intake}`),
      obj: erxShipmentDate,
    });
  });

  // Sort by dismissed (non-dismissed first)
  //      by date (descending)
  return result.sort((a, b) => {
    if (a.dismissed && !b.dismissed) {
      return 1;
    }
    if (!a.dismissed && b.dismissed) {
      return -1;
    }

    return Date.parse(b.date) - Date.parse(a.date);
  });
};

const SupportButton = ({ useLiveChat = true }) => {
  const handleClick = useLiveChat
    ? openLiveChat
    : () => window.open('https://www.app.elationpassport.com/passport/login/');

  return (
    <div className="progress_tracker__upnext__progressbar__flex elation">
      <PrimaryButton onClick={handleClick} size="small" text="Customer Support" />
    </div>
  );
};

const openLiveChat = () => {
  try {
    window.Intercom('showSpace', 'messages');
  } catch {}
};

const ProgressTrackerUpNext = () => {
  const dispatch = useDispatch();
  const productCtx = useContext(ProductContext);

  const customerId = useAppSelector(selectCustomerId);
  const currentIntake = useAppSelector((state) => selectLastProductIntake(state, productCtx.selectedProduct));
  const prescriptions = currentIntake.get('prescriptions')?.toJS() ?? [];
  const allValidShipments = [] as any[];
  let totalOfShipments = 0;
  prescriptions.forEach((prescription) => {
    totalOfShipments += (prescription?.refills ?? 0) + 1;
    const validShipments = prescription?.pharmacy_shipments?.filter((shipment) => !shipment?.lost_or_returned) ?? [];
    allValidShipments.push(...validShipments);
  });
  const validShipmentsLength = allValidShipments.length;
  const totalShipments = totalOfShipments;
  const remainingRefills = totalShipments - validShipmentsLength;
  const clinicianQuestionsCompletedAt = currentIntake?.get('clinician_questions_completed_at');
  const patientPortalRegisteredAt = useAppSelector(selectCustomerQuestionnaire).get('patient_portal_registered_at');
  const erxShipmentDates =
    useAppSelector((state) => selectAllErxShipmentDates(state, productCtx?.selectedProduct)) || [];
  const currentStrictlyIntakeSteps = useAppSelector((state) =>
    selectStrictlyProductIntakeStepsByLastIntake(state, productCtx.selectedProduct),
  );
  const total = currentStrictlyIntakeSteps.size;
  const position = currentStrictlyIntakeSteps.findIndex((value) => !value.get('completed'));
  const isOnboarding = ONBOARDING_TYPES.includes(currentIntake?.get('type'));
  const nextStep = useAppSelector((state) =>
    selectFurthestProductLastIntakeStepPath(state, productCtx.selectedProduct),
  );
  const labInfo = useAppSelector(selectCustomerLabOrders)
    .toJS()
    .find((order) => order.intake_name === currentIntake?.get('name')) || { shipped_at: null };
  const labOrderResults = useAppSelector((state) => selectProductLabOrderResults(state, productCtx.selectedProduct));

  const isIdReviewPending = nextStep && nextStep.includes('id_review');
  const percentage = total === 0 ? 0 : position / total;
  const completeness = isIdReviewPending ? 99 : (percentage * 99).toFixed(0) || 0; // We don't need to show 100% (#183024343)
  const dismissedNotifications = useAppSelector(selectCustomerDismissedNotifications);
  const cancelled = useAppSelector((state) => selectors.selectIsProductCancelled(state, productCtx?.selectedProduct));
  const daysUntilNextRefill = currentIntake?.get('days_until_next_refill');
  const [modalButtonText, setModalButtonText] = useState('REQUEST MEDICATION');
  const [filteringOption, setFilteringOption] = useState<Filters>('all');
  const completeOnboarding = () => dispatch(push(nextStep));

  useEffect(() => {
    if (window.innerWidth > 480) {
      setModalButtonText('REQUEST MEDICATION');
    } else {
      setModalButtonText('REQUEST MEDS');
    }
  }, []);

  const handleOnClick = () => {
    const cmdType = 'answer_questions';
    const params = { patient_portal_registered_at: dayjs() };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: {},
    });
    dispatch(action);
  };
  const isDismissed = useCallback(
    (name) => dismissedNotifications.some((n) => n.get('notification_name') === name),
    [dismissedNotifications],
  );

  const AppointmentScheduleButton = () => {
    const handleClick = () => dispatch(push(Routes.ScheduleAppointment));

    return (
      <div className="progress_tracker__upnext__progressbar__flex elation">
        <PrimaryButton onClick={handleClick} size="small" text="schedule consultation" />
      </div>
    );
  };

  const dismissNotification = (name) => {
    const action = actions.apiRequestUserCommand({
      cmdType: 'dismiss_notification',
      params: { notification_name: name },
      context: {},
    });
    dispatch(action);
  };

  const filteringOptions: {
    title: string;
    value: Filters;
    selected: boolean;
  }[] = [
    {
      title: 'All Notifications',
      value: 'all',
      selected: filteringOption === 'all',
    },
    {
      title: 'New Notifications',
      value: 'new',
      selected: filteringOption === 'new',
    },
    {
      title: 'Dismissed Notifications',
      value: 'dismissed',
      selected: filteringOption === 'dismissed',
    },
  ];

  const notifications = collectNotifications(isDismissed, currentIntake, labInfo, labOrderResults, erxShipmentDates);
  const newNotificationsCount = notifications.filter((a) => !a.dismissed).length;
  const nextElationReleaseDate = currentIntake.get('next_elation_note_release_time');

  const isKing = productCtx?.selectedProduct === AvailableProducts.King;
  const isMagician = productCtx?.selectedProduct === AvailableProducts.Magician;
  const isWarrior = productCtx?.selectedProduct === AvailableProducts.Warrior;
  const isLover = productCtx?.selectedProduct === AvailableProducts.Lover;

  const showMedicalQuestionnaire = !clinicianQuestionsCompletedAt;
  const showElationPassportRegistration = !!clinicianQuestionsCompletedAt && !patientPortalRegisteredAt;
  const showBaselineLabResults = (isKing || isMagician) && !!clinicianQuestionsCompletedAt && !labOrderResults.size;

  const showDoctorReviewKingOnboarding = isOnboarding && isKing && !!labOrderResults.size;
  const showDoctorReviewMagicianOnboarding = isOnboarding && isMagician && !!labOrderResults.size;
  const showDoctorReviewLoverWarriorOnboarding =
    isOnboarding && (isLover || isWarrior) && !!clinicianQuestionsCompletedAt;
  const showDoctorReviewFollowUpAndSustain = !isOnboarding && !!clinicianQuestionsCompletedAt;

  const showOrderFulfillment = !!prescriptions && remainingRefills > 0;
  const showRenewalQuestionnaire = prescriptions.length > 0 && remainingRefills <= 0;

  const customer = useAppSelector(selectCustomer);
  const pendingApointmentSchedule = customer.get('pending_appointment_schedule');
  const currentAppointment = customer.get('current_appointment');
  const questLabBaselineResults = isKing && customer.get('king_lab_kit_type') === KingV2LabKitTypes.WalkIn;

  const AttendAppointmentButton = () => {
    const handleClick = () => window.open(currentAppointment.get('video_url'), '_blank');

    return (
      <div className="progress_tracker__upnext__progressbar__flex elation">
        <PrimaryButton onClick={handleClick} size="small" text="Attend Consultation" />
      </div>
    );
  };

  const baselineAtHomeLabResultsBlock = (
    <ul>
      <li>
        You will receive your at-home lab kit <u>2-7 business days</u> after completing your medical questionnaire
        (unless you are using your own labs). You will receive a notification once your lab has shipped.
      </li>
      <li>
        Once you ship the kit back, please allow <u>up to 4 business days</u> for results to be posted. You will receive
        a notification when your results are ready.
      </li>
      <li>
        Once your results are ready, your doctor will review them within <u>1-2 business days</u> and determine the best
        treatment plan for you.
      </li>
    </ul>
  );

  const baselineQuestLabResultsBlock = (
    <ul>
      <li>
        Set up a time to walk in to your{' '}
        <a
          style={{ color: '#000', textDecoration: 'underline' }}
          href="https://www.questdiagnostics.com/locations/search"
          rel="noreferrer"
          target="_blank"
        >
          nearest Quest Labs
        </a>{' '}
        within the next few days.
      </li>
      <li>
        A requisition form for Quest Labs will be emailed to you shortly, along with instructions for how to plan for
        your visit and how to return your lab results once you receive them.
      </li>
      <li>
        Once your results are ready, your doctor will review them within 1-2 business days and determine the best
        treatment plan for you.
      </li>
    </ul>
  );

  const baselineLabResultsBlock = (
    <div className="progress_tracker__upnext__reason">
      <HeadingWithIcon icon={IconArrowUp}>
        <p className="heading">{questLabBaselineResults ? 'Visit Quest Labs' : 'Complete At-Home Lab Kit'}</p>
      </HeadingWithIcon>
      <div className="progress_tracker__upnext__reason__title">
        Your baseline (pre-treatment) lab results will be used by your doctor to determine the treatment plan that is
        best for you.
        <br />
        <br />
        <b>What to expect:</b>
        <br />
        <br />
        {questLabBaselineResults ? baselineQuestLabResultsBlock : baselineAtHomeLabResultsBlock}
      </div>
      <SupportButton />
    </div>
  );

  return (
    <div className="progress_tracker__upnext">
      <div className="progress_tracker__content reason">
        <div>
          {cancelled ? (
            <Cancelled uncancel={() => dispatch(push(Routes.ManageSubscription))} />
          ) : (
            <div>
              <TitleWithIcon icon={<ReactSVG src={DottedClock} className="svg-icon" />}>Up Next</TitleWithIcon>
              {isKing && pendingApointmentSchedule && (
                <div className="progress_tracker__upnext__reason">
                  <HeadingWithIcon icon={IconArrowUp}>
                    <p className="heading">Schedule Video Consultation</p>
                  </HeadingWithIcon>
                  <p className="progress_tracker__upnext__reason__title">
                    In order to begin treatment you will need to schedule a video consult with your Maximus doctor.
                  </p>
                  <AppointmentScheduleButton />
                </div>
              )}
              {isKing && currentAppointment && (
                <div className="progress_tracker__upnext__reason">
                  <HeadingWithIcon icon={IconArrowUp}>
                    <p className="heading">Attend Video Consultation</p>
                  </HeadingWithIcon>
                  <p className="progress_tracker__upnext__reason__title">
                    Your appointment has been scheduled with {currentAppointment.get('doctor_name')} for{' '}
                    <b>{currentAppointment.get('started_at')}</b>. You will receive an email reminder of your
                    appointment which will also include a link to attend your video consult.
                  </p>
                  <div className="progress_tracker__upnext__progressbar__flex elation">
                    <AttendAppointmentButton />
                  </div>
                </div>
              )}
              {showMedicalQuestionnaire && (
                <div className="progress_tracker__upnext__reason">
                  <HeadingWithIcon icon={IconArrowUp}>
                    <p className="heading">Medical Questionnaire</p>
                  </HeadingWithIcon>
                  <p className="progress_tracker__upnext__reason__title">
                    Complete your intake form so your doctor can review and offer medical guidance and a prescription
                    that best suits your needs.
                    <br />
                    <br />
                    If qualified, your doctor will have your meds shipped right to your door.
                  </p>
                  <div className="progress_tracker__upnext__progressbar__flex">
                    <div>
                      <PrimaryButton
                        data-testid="next-step"
                        onClick={completeOnboarding}
                        size="small"
                        text={modalButtonText}
                      />
                    </div>
                    <div className="progress_tracker__upnext__progressbar">
                      <div className="line">
                        <div style={{ width: `${completeness}%` }} className="line__inner" />
                      </div>
                      <div className="value">{completeness}%</div>
                    </div>
                  </div>
                </div>
              )}
              {isOnboarding && (
                <>
                  {showElationPassportRegistration && (
                    <div className="progress_tracker__upnext__reason">
                      <HeadingWithIcon icon={IconArrowUp}>
                        <p className="heading">Elation Passport Registration</p>
                      </HeadingWithIcon>
                      <p className="progress_tracker__upnext__reason__title">
                        Elation Patient Passport is the platform you will use to communicate with your doctor. You will
                        receive an email invite to register to Elation shortly (typically 1-2 business days).
                        <br />
                        <br />
                        Once you receive the invite, sign up so you and your doctor can message one another.
                      </p>
                      <div className="progress_tracker__upnext__progressbar__flex elation">
                        <PrimaryButton
                          className="i_registered w50 registeredButton"
                          onClick={handleOnClick}
                          size="small"
                          text="I've registered"
                        />
                        <SupportButton />
                      </div>
                    </div>
                  )}

                  {showBaselineLabResults && baselineLabResultsBlock}
                </>
              )}

              {!(prescriptions.length > 0) && (
                <>
                  {showDoctorReviewKingOnboarding && (
                    <div className="progress_tracker__upnext__reason">
                      <HeadingWithIcon icon={IconArrowUp}>
                        <p className="heading">Doctor Review</p>
                      </HeadingWithIcon>
                      <p className="progress_tracker__upnext__reason__title">
                        Thank you for completing your medical questionnaire and providing pre-treatment lab results.
                        <br />
                        <br />
                        Your doctor will review your lab results and medical questionnaire within{' '}
                        <u>two business days</u>. You will receive a notification if your doctor has questions,
                        otherwise you will receive a notification once your prescription has been sent to the pharmacy
                        (if qualified).
                      </p>
                      <SupportButton />
                      <p className="progress_tracker__upnext__reason__title">
                        *Important: Only message your doctor on matters related to your health and clinical status.
                        Message support for all other matters or you will experience a <u>delayed response</u>.
                      </p>
                    </div>
                  )}

                  {showDoctorReviewMagicianOnboarding && (
                    <div className="progress_tracker__upnext__reason">
                      <HeadingWithIcon icon={IconArrowUp}>
                        <p className="heading">Doctor Review</p>
                      </HeadingWithIcon>
                      <p className="progress_tracker__upnext__reason__title">
                        Thank you for completing your medical questionnaire and providing pre-treatment lab results.
                        <br />
                        <br />
                        Your doctor will review your lab results and medical questionnaire within{' '}
                        <u>two business days</u>. You will receive a notification if your doctor has questions,
                        otherwise you will receive a notification once your prescription has been sent to the pharmacy
                        (if qualified).
                      </p>
                      <SupportButton />
                      <p className="progress_tracker__upnext__reason__title">
                        *Important: Only message your doctor on matters related to your health and clinical status.
                        Message support for all other matters.
                      </p>
                    </div>
                  )}

                  {showDoctorReviewLoverWarriorOnboarding && (
                    <div className="progress_tracker__upnext__reason">
                      <HeadingWithIcon icon={IconArrowUp}>
                        <p className="heading">Doctor Review</p>
                      </HeadingWithIcon>
                      <p className="progress_tracker__upnext__reason__title">
                        Thank you for completing your medical questionnaire.
                        <br />
                        <br />
                        Your doctor will review your medical questionnaire within <u>two business days</u>. You will
                        receive a notification if your doctor has questions, otherwise you will receive a notification
                        once your prescription has been sent to the pharmacy (if qualified).
                      </p>
                      <SupportButton />
                      <p className="progress_tracker__upnext__reason__title">
                        *Important: Only message your doctor on matters related to your health and clinical status.
                        Message support for all other matters or you will experience a <u>delayed response</u>.
                      </p>
                    </div>
                  )}

                  {showDoctorReviewFollowUpAndSustain && (
                    <div className="progress_tracker__upnext__reason">
                      <HeadingWithIcon icon={IconArrowUp}>
                        <p className="heading">Doctor Review</p>
                      </HeadingWithIcon>
                      <p className="progress_tracker__upnext__reason__title">
                        Thank you for completing your medical questionnaire.
                        <br />
                        <br />
                        Your renewal is estimated to begin processing on {nextElationReleaseDate}. You will receive a
                        notification if your doctor has questions, otherwise you will receive a notification once your
                        prescription has been sent to the pharmacy (if qualified).
                      </p>
                      <SupportButton />
                      <p className="progress_tracker__upnext__reason__title">
                        *Important: Only message your doctor on matters related to your health and clinical status.
                        Message support for all other matters or you will experience a <u>delayed response</u>.
                      </p>
                    </div>
                  )}
                </>
              )}

              {showOrderFulfillment && (
                <div className="progress_tracker__upnext__reason">
                  <HeadingWithIcon icon={IconArrowUp}>
                    <p className="heading">Order Fulfillment</p>
                  </HeadingWithIcon>
                  <p className="progress_tracker__upnext__reason__title">
                    Your doctor has completed their review and submitted your prescription to the pharmacy.
                    <br />
                    <br />
                    Our pharmacy partner will fill your order within the next 2-7 days. From there, your order will ship
                    and reach you in 3-5 days. You will receive a notification with your tracking number once your
                    medication has shipped.
                    <br />
                    {remainingRefills > 0 && totalShipments > 1 && (
                      <>
                        <br />
                        If your prescription has automatic refills, you will receive a notification with each shipment.
                        <br />
                        <br />
                        So far you have been sent{' '}
                        <b>
                          {validShipmentsLength} of {totalShipments} total shipment{totalShipments > 1 ? 's' : ''}
                        </b>{' '}
                        (includes initial shipment and subsequent refills).
                        <br />
                        <br />
                        Once all refills have been sent you will be required to complete a short renewal questionnaire
                        to continue treatment.
                      </>
                    )}
                    <br />
                    <br />
                    <div className="maintain">
                      <div>Maintain Your Medical Records</div>
                      <div />
                    </div>
                    <br />
                    <div className="text12">
                      To ensure you receive the highest quality care be sure to update your medical records whenever
                      you: change medications, are diagnosed with a new medical condition, or experience any
                      allergies/adverse events you believe are related to your Maximus protocols. You should also update
                      your medical records whenever you are not experiencing the desired effects of your Maximus
                      protocols.
                    </div>
                  </p>
                  <div className="progress_tracker__upnext__progressbar__flex elation">
                    <PrimaryButton
                      onClick={() => {
                        if (
                          (window.updateMedicalQuestionnaireEnabledForKing &&
                            AvailableProducts.King === productCtx.selectedProduct!) ||
                          (window.updateMedicalQuestionnaireEnabledForLover &&
                            AvailableProducts.Lover === productCtx.selectedProduct!) ||
                          (window.updateMedicalQuestionnaireEnabledForMagician &&
                            AvailableProducts.Magician === productCtx.selectedProduct!) ||
                          (window.updateMedicalQuestionnaireEnabledForWarrior &&
                            AvailableProducts.Warrior === productCtx.selectedProduct!)
                        ) {
                          dispatch(
                            push(Routes.UpdateQuestionnaire.replace(':product', productCtx.selectedProduct || '')),
                          );
                        } else {
                          window.open('https://maximustribe.com/passport', '_blank');
                        }
                      }}
                      size="small"
                      text="My medical records have changed"
                      style={{
                        width: 'auto',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                      }}
                    />
                  </div>
                </div>
              )}

              {showRenewalQuestionnaire && (
                <div className="progress_tracker__upnext__reason">
                  <HeadingWithIcon icon={IconArrowUp}>
                    <p className="heading">Renewal Questionnaire</p>
                  </HeadingWithIcon>
                  <p className="progress_tracker__upnext__reason__title">
                    {daysUntilNextRefill} DAYS UNTIL RENEWAL QUESTIONNAIRE AVAILABLE
                    <br />
                    <br />
                    Your last refill has been sent. You will need to complete a renewal questionnaire before you can
                    continue treatment.
                    <br />
                    <br />
                    To ensure you receive your next prescription before your current prescription runs out, you are
                    encouraged to complete your renewal questionnaire as soon as it becomes available. This allows time
                    for doctor review and pharmacy order fulfillment.
                    <br />
                    <br />
                    You will be notified by email when your renewal questionnaire is ready for you.
                  </p>
                  <SupportButton />
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <TitleWithIcon badge={newNotificationsCount} icon={<ReactSVG src={AlarmIcon} className="svg-icon" />}>
            <span className="pl6">Notifications</span>

            <Popover2
              content={
                <Menu className="notifications-popup-menu">
                  {filteringOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      text={option.title}
                      className={option.selected ? 'selected' : ''}
                      onClick={() => {
                        setFilteringOption(option.value);
                      }}
                    />
                  ))}
                </Menu>
              }
              position="bottom-right"
            >
              <svg width="6" height="23" viewBox="0 0 6 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" transform="rotate(90 3 3)" fill="#CDCDCD" />
                <circle cx="3" cy="11" r="3" transform="rotate(90 3 11)" fill="#CDCDCD" />
                <circle cx="3" cy="20" r="3" transform="rotate(90 3 20)" fill="#CDCDCD" />
              </svg>
            </Popover2>
          </TitleWithIcon>
          {newNotificationsCount === 0 && (
            <div className="progress_tracker__upnext__no_notifications">No new notifications.</div>
          )}
          {notifications
            .filter((a) => {
              switch (filteringOption) {
                case 'new':
                  return !a.dismissed;
                case 'dismissed':
                  return a.dismissed;
                default:
                  return true;
              }
            })
            .map((notification: Notification) => (
              <TrackerNotification
                key={`${notification.type}-${notification.date}`}
                currentIntake={currentIntake}
                customerId={customerId}
                dismiss={dismissNotification}
                notification={notification}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
export default ProgressTrackerUpNext;
