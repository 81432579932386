import React from 'react';
import { connect } from 'react-redux';

import { P } from 'app/components/common/Typography';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';
import BulletCheckmark from 'images/Icon-Bullet-Checkmark.svg';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import './css/Acknowledgement.scss';
import protocolNames from 'app/utils/protocolNames';

const mapStateToProps = (_) => {
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectFurthestOnboardingStepPath({ customer: stateAfterSuccess }));

  return {
    onSuccessActionCreator,
  };
};

const benefits = ['Increased energy', 'More lean mass', 'Greater motivation', 'More confidence'];
const access = [
  'Prescription medication capsules (if qualified)',
  'Licensed doctor consultations',
  'Live coaching & community',
  'Free 2 day priority shipping for meds',
];

const Acknowledgement = ({ onSuccessActionCreator, step, dispatch }) => {
  const onClick = () => {
    const cmdType = 'answer_questions';
    const params = { [step]: true };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  return (
    <div className="p16 king-intro">
      <h1>
        The <span>{protocolNames['king']}</span> Protocol
      </h1>
      <P className="mt16">
        The {protocolNames['king']} Protocol uses a convenient, prescription tablet that stimulates your body’s own
        natural testosterone production. It’s effective – with none of the painful injections, messy creams, or side
        effects associated with other testosterone treatments.
      </P>
      <P className="bold mt20">Benefits include:</P>
      <ul>
        {benefits.map((benefit) => (
          <li key={benefit}>
            <HeadingWithIcon icon={BulletCheckmark} text={benefit} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <P className="bold mt36">
        Starting at $99<sup>99</sup> per month you have access to:
      </P>
      <ul>
        {access.map((a) => (
          <li key={a}>
            <HeadingWithIcon icon={BulletCheckmark} text={a} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <PrimaryButton className="mt16 mb24" data-testid="next-step" text="Start my journey" onClick={onClick} />
      <div className="mb16 center-align">
        To learn more about the {protocolNames['king']} Protocol visit:{' '}
        <a target="_blank" href="https://maximustribe.com/testosterone/enclomiphene" rel="noreferrer">
          maximustribe.com/testosterone/enclomiphene
        </a>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Acknowledgement);
